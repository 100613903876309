import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {
  data;
  loading = true;
  id:any;
  clientForm:FormGroup;

  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    public route: ActivatedRoute,
  ) {


   }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.getClient();
   
  }

   getClient(){
      this.sgs.request('get', 'client/getClientWithID', {id:this.id}, async (response) => {
      if( response.success ){
          this.data = response.data;
          this.buildForms();
          this.loading = false;
      }else{
      }
    },{cache:true, describe: "Error getting clients!" });

   
  }

  buildForms(){
      this.clientForm = this.fb.group({
        companyname: [this.data.companyname, [Validators.required, Validators.minLength(2)]],
        fname: [this.data.fname, [Validators.required, Validators.minLength(2)]],
        lname: [this.data.lname, [Validators.required, Validators.minLength(2)]],
        contact: [this.data.contact],
        address: [this.data.address],
        email: [this.data.email, [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      })
    
  }

  // ngOnDestroy(){
  //   this.socketInstance.unsubscribe();
  // }

  submit(){
    this.data.companyname = this.clientForm.value.companyname;
    this.data.fname = this.clientForm.value.fname;
    this.data.lname = this.clientForm.value.lname;
    this.data.contact = this.clientForm.value.contact;
    this.data.address = this.clientForm.value.address;
    this.data.email = this.clientForm.value.email;

    this.sgs.request('post', 'client/updateClient', this.data, async (response) => {
      if(response.success){
        this.sgs.Toaster('success', 'Success', 'Client has been updated successfully.');
        this.buildForms();
      }
    }, { cache:true, describe: "ERROR: Client update" })
  }







  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }



  

}
