import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';


@Component({
  selector: 'ngx-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  loading = true;
  public form: any;
  data;
  uid; 
  docId;
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public sgs: SharedGlobalService
  ) {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      role:         ['', [Validators.required]],
      username:     ['', [Validators.required]],
      lname:        ['', [Validators.required]],
      fname:        ['', [Validators.required]],
      mname:        [''],
    })
  }

  ngOnInit() {
    this.getUser();
  }

  getUser(){
    this.sgs.request('get', 'user/getUser', {id: this.uid}, async (response) => {
      if(response.success){
        this.form = this.formBuilder.group({
          role:         [response.data.role],
          username:     [response.data.username],
          lname:        [response.data.lname],
          fname:        [response.data.fname],
          mname:        [response.data.mname]
        })
      }
    });
  }


  addUser(data){
    this.sgs.request('post', 'user/createUser', data.value, async (response) => {
      if(response.success){
        this.sgs.Toaster('success', 'Success', 'New user has been added.');
      }
    })
  }

  updateUser(data){
    data.value.id = this.docId;
    this.sgs.request('post', 'user/updateUser', data.value, async (response) => {
      if(response.success){
        this.sgs.Toaster('success', 'Success', 'User Information has been updated.');
      }
    }, { cache:true, describe: "ERROR: updating user" })
  }

  closeModal() {
    this.activeModal.close();
  }

}