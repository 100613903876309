import { Title } from '@angular/platform-browser';
import { DatePipe, Location } from '@angular/common';
import { Injectable, Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToasterService, ToasterConfig, Toast, BodyOutputType } from 'angular2-toaster';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import * as md5 from 'md5';
import { AuthService } from './auth.service';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NumberWithCommas, ImageGallery } from '../pipes/dataFilter';
import { ConnectionService } from './connection.service';

@Injectable()
export class SharedGlobalService {

// public connection = "https://damsel.myempower.app";
// public connection = "https://demo.myempower.app";
  public connection:String =  this.cs.connection;
  // public connection = "http://192.168.0.7:2000";

  public server:any = { status:true, message: "online" };
  public config: ToasterConfig;
  public toaster: ToasterService;
  public notificationStatus: Boolean = false;
  public loading:Boolean = true;
  public modalLoading:Boolean = true;
  public selectorLoader:Boolean = false;
  public submitting:Boolean = false;
  public multifilter:any = [];
  public filterQuery:any = "";
  public usePps:boolean = true;
  public version = '1.0.1';

  @Output() public clearTinymce = new EventEmitter();
  @Output() public setBrowserTitle = new EventEmitter();
  @Output() public notificationEmitter = new EventEmitter();
  @Output() public userLogout = new EventEmitter();
  @Output() public printerEmitter = new EventEmitter();

  constructor(
      public http:Http,
      public router: Router,
      public auth: AuthService,
      public cs: ConnectionService,
      public modalService: NgbModal,
      public location: Location,
      public dp: DatePipe,
      public browserTitle: Title
  ){
      this.setBrowserTitle.subscribe( title => {
          this.browserTitle.setTitle([title, '—', 'HMED'].join(' '));
      });
  }

    /**
     * @param {String} method get, post, put
     * @param {String} endpoint API model/route
     * @param {Any} param  JSON data
     * @param {Function} callback returns callback
     * @return {Object} subscribed data
     */




    public request(method:String, endpoint:String, param?:any, callback?:any, options?:any){
      
        let connection = this.connection + '/api';
        switch(method){
            case 'get':
                this.StartLoading(options);
                param = param?typeof param==='object'?JSON.stringify(param):undefined:undefined;
                return this.http.get(`${connection}/${endpoint}`, this.auth.Headers(param))
                .map(res => res.json())
                .subscribe( res => {
                    if( res.success ){
                        this.StopLoading();
                        return callback(res);
                    }else{
                        // console.log('Error 1', endpoint)
                        this.StopLoading();
                        return this.fail(res, callback, options);
                    }
                }, error => {
                    this.StopLoading();
                    this.RequestCache(method, endpoint, param, options);
                    this.Toaster('error', 'Server error', `Server error ${error}`);
                });

            case 'post':
                this.StartLoading(options);
                return this.http.post(`${connection}/${endpoint}`, param, this.auth.Headers())
                .map(res => res.json())
                .subscribe( res => {
                    
                    if( res.success ){
                        this.StopLoading();
                        return callback(res);
                    }else{
                        // console.log('Error 2')
                        this.StopLoading();
                        return this.fail(res, callback, options);
                    }
                }, error => {
                    // console.log('POST Request Error: ', error)
                    this.StopLoading();
                    this.RequestCache(method, endpoint, param, options);
                    this.Toaster('error', 'Server error', `Cannot connect to the server.<br />Please contact your administrator.`);
                });

            case 'put':
                this.StartLoading(options);
                return this.http.put(`${connection}/${endpoint}`, param, this.auth.Headers())
                .map(res => res.json())
                .subscribe( res => {
                    if( res.success ){
                        this.StopLoading();
                        return callback(res);
                    }else{
                        // console.log('Error 3')
                        this.StopLoading();
                        return this.fail(res, callback, options);
                    }
                }, error => {
                    this.StopLoading();
                    this.RequestCache(method, endpoint, param, options);
                    this.Toaster('error', 'Server error', `Cannot connect to the server.<br />Please contact your administrator.`);
                });
        }
    }

    public back(){
        this.location.back();
    }

    public goto(location){
        this.router.navigate([location]);
    }

    public StartLoading(options?){
        if(!this.submitting){
            this.loading = true;
            this.modalLoading = true;
        }

        for(let prop in options){
            switch(prop){
                case 'socketLoader':
                 this.loading = options[prop] === true ? true : false;
                 this.modalLoading = options[prop] === true ? true : false;
                break;
                case 'socketModalLoader':
                 this.modalLoading = options[prop] === true ? true : false;
                break;
                case 'selectorLoader':
                 this.selectorLoader = options[prop] === true ? true : false;
                break;
            }
         }

    }

    public StopLoading(){
        this.loading = false;
        this.submitting = false;
        this.modalLoading = false;
        this.selectorLoader = false;
    }

    public fail(res, callback, options?:any){
        // console.log('Has Fail', res)
        if( res.codeMessage !== undefined ){
            this.kill(res);
        }else{
            if(res.toaster){
                let toast = (()=>{
                    this.Toaster('error', 'Request denied', (()=>{
                        if( typeof res.message === 'object' )
                            return JSON.stringify(res.message)
                        return res.message;
                    })());
                });
                options?options.hasOwnProperty('toaster')?options.toaster?toast():'':toast():toast();
            }
            return callback(res);
        }
    }

    public kill(data){
        // this.Toaster('error', data.message, data.codeMessage);
        // console.info(data.message, data.codeMessage);
        this.auth.logout();
    }

    public RequestCache(method, endpoint, param, options){
        if(options){
            for(var opt in options) {
                if( opt === 'cache' && options[opt] === true ){
                    let data = {
                        cache_id: md5([Math.random(), Math.random(), (new Date()).getMilliseconds()].join()),
                        uid: this.auth.getTokenData('id'),
                        user_role: this.auth.getTokenData('role'),
                        method: method,
                        endpoint: endpoint,
                        param: param,
                        routes: this.router.url,
                        describe: options['describe'] ? options['describe'] : '',
                        cache_date: this.dp.transform(new Date(), 'medium')
                    };
                    this.InsertCache(data);
                }
            }
        }
    }

    public CacheManager(){}
    public syncData(){}

    public InsertCache(data){
        try{
            let rc = JSON.parse(localStorage.getItem('request-cache')) || [];
            if( rc.length ){
                let tempData = Object.assign({}, data);
                let isRecordExists = false;
                rc.map( record => {
                    let tempRecord = Object.assign({}, record);
                    tempRecord.cache_date = tempData.cache_date =
                    tempRecord.cache_id = tempData.cache_id = undefined;
                    if(JSON.stringify(tempRecord) === JSON.stringify(tempData)){
                        isRecordExists = true;
                        tempRecord = tempData = undefined;
                    }
                });
                if(!isRecordExists){
                    rc.push(data);
                    localStorage.setItem('request-cache', JSON.stringify(rc));
                    rc = undefined;
                    this.notificationEmitter.emit();
                }
            }else{
                localStorage.setItem('request-cache', JSON.stringify([data]));
            }
        }catch(e){
            this.Toaster('error', e.name, e.message);
            localStorage.removeItem('request-cache');
        }
    }

    public SetCachedData(data){
        localStorage.setItem('request-cache', JSON.stringify(data));
    }

    public getCachedData(options?:any){
        try{
            let rc = JSON.parse(localStorage.getItem('request-cache')) || [];
            let uid = this.auth.getTokenData('id');
            return rc.filter( record => record.uid === uid).reverse();
        }catch(e){
            this.Toaster('error', e.name, e.message);
            localStorage.removeItem('request-cache');
        }
    }

    public removeCachedData(id){
        try{
            let rc = JSON.parse(localStorage.getItem('request-cache')) || [];
            let uid = this.auth.getTokenData('id');
            if(rc.length){
                rc.map( (record, index) => {
                    if(record.cache_id === id && record.uid === uid){
                        rc.splice(index, 1)
                    }
                });
                this.SetCachedData(rc);
                this.notificationEmitter.emit();
            }
        }catch(e){
            this.Toaster('error', e.name, e.message);
            localStorage.removeItem('request-cache');
        }
    }

    /**
     * @param {String} position 'toast-top-full-width', 'toast-bottom-full-width', 'toast-top-left', 'toast-top-center', 'toast-top-right', 'toast-bottom-right', 'toast-bottom-center', 'toast-bottom-left', 'toast-center'
     * @param {String} animationType 'fade', 'flyLeft', 'flyRight', 'slideDown', 'slideUp'
     * @param {Number} timeout 5000
     * @param {Boolean} isNewestOnTop true
     * @param {Boolean} isHideOnClick true
     * @param {Boolean} isDuplicatesPrevented false
     * @param {Number} toastsLimit 5
     * @return {ToasterConfig}
     */
    public TConfig(position:string = 'toast-bottom-right', animationType:string = 'flyRight', timeout:number = 5000, isNewestOnTop:boolean = true, isHideOnClick:boolean = true, isDuplicatesPrevented:boolean = false, toastsLimit:number = 5 ){
        this.config = new ToasterConfig({
            positionClass: position,
            animation: animationType,
            timeout: timeout,
            newestOnTop: isNewestOnTop,
            tapToDismiss: isHideOnClick,
            preventDuplicates: isDuplicatesPrevented,
            limit: toastsLimit,
        });
    }

    public ToasterInit(toaster:ToasterService){
            this.setToaster(toaster);
            this.TConfig();
        return this.config;
    }

    public setToaster(toaster:ToasterService){
        this.toaster = toaster;
    }

    /**
     * @param {String} type 'default', 'info', 'success', 'warning', 'error'
     * @param {String} title 'Your title here..'
     * @param {String} body 'Your content here..'
     * @param {Number} timeout 5000
     * @param {Boolean} closeButton true or false
     * @return {Angular2Toaster}
     */
    public Toaster(type:string = 'default', title:string = 'Your title here..', body:string = 'Your content here..', timeout:number = 5000, closeButton:boolean = true){
        const toast: Toast = {
            type: type,
            title: title,
            body: body,
            timeout: timeout,
            showCloseButton: closeButton,
            bodyOutputType: BodyOutputType.TrustedHtml,
        };
        this.toaster.popAsync(toast)
    }

    public getTime(format?:string){
        let date = new Date();
        switch(format){
            case 'hh':
                return [date.getHours()].join('');
            case 'mm':
                return [date.getMinutes()].join('');
            case 'hhmm':
                return [date.getHours(), date.getMinutes()].join('');
            case 'hhmmss':
                return [date.getHours(), date.getMinutes(), date.getSeconds()].join('');
            default:
                return [date.getHours(), date.getMinutes()].join('');
        }
    }

    /**
     * @param {String} text 'String only that possibly having white spaces'
     */
    public trim(text:string){
        return text.trim();
    }

    ResponseSocket(name?){
        let socket = io.connect(this.connection);
        return new Observable(observer => {
            socket.on(name, (data) => {
                this.server.status = true;
                this.server.message = "online";
                observer.next(data);
            });

            socket.on('reconnect_error', () => {
                //  console.clear();
                this.server.status = false;
                this.server.message = "offline";
            });

            socket.on('reconnect', () => {
                //  console.clear();
                this.server.status = true;
                this.server.message = "online";
            });

            return () => {
                socket.disconnect();
            };
        }) as any;
    }

    RequestSocket(name?, data?){
        let socket = io.connect(this.connection);
            socket.emit(name, data);
    }

    Lan(){
        try{
            return { status: navigator.onLine, message: navigator.onLine?"online":"offline" };
        }catch(e){
            console.error("Browser doesn't support navigation status");
            return { status: true, message: "Browser doesn't support navigation status" };
        }
    }
    // added at 06/21/19
    public MultiSearch(arr){
      this.Modal({
          header: `Select Filters`,
          content: {
            type: 'array-object',
            data: arr,
            display: [ { property: 'nicename', nicename: "Column" }, ],
            return: ['name', 'nicename'],
            search: true,
            searchTo: ['name', 'nicename'],
          },
          accept: 'single',
          type: 'selector',
        }, { size: 'md' })

        .selected.subscribe( response => {

          if( this.multifilter.length ){
              if( !this.multifilter.filter(e => e['name'] === response.data[0]).length ){
                  this.multifilter.push({
                      name: response.data[0],
                      nicename: response.data[1],
                  });
              }
          }else{
              this.multifilter.push({
                  name: response.data[0],
                  nicename: response.data[1],
              });
          }

          this.filterQuery = "";

        });
  }

  public filterRemove(item, v){
      return this.multifilter.filter( (result, index) => {
          if( result['name'] === item ){
              this.multifilter.splice(index, 1);
              this.filterQuery = "";
          }
      });
  }



    /**
     * @param {Object} objects {header: 'header title', content: 'content/context'}
     * @param {Object} options (size | 'sm', 'md', 'lg') (backdrop | 'static') (container | 'ng-layout', 'div', etc..)
     * @return {Modal}
     */
    public Modal(objects?:any, options?:any, header?:any) {
        if(options !== undefined && options.component !== undefined){
            const activeModal = this.modalService.open(options.component, {
                size: options !== undefined && options.size !== undefined ? options.size : 'sm',
                backdrop: options !== undefined && options.backdrop !== undefined ? options.backdrop : 'static',
                container: options !== undefined && options.container !== undefined ? options.container : 'nb-layout',
            });
            for(let data in objects){
                activeModal.componentInstance[data] = objects[data];
            }
            return activeModal.componentInstance;
        }else{
            const activeModal = this.modalService.open(GlobalModal, {
                size: options !== undefined && options.size !== undefined ? options.size : 'sm',
                backdrop: options !== undefined && options.backdrop !== undefined ? options.backdrop : 'static',
                container: options !== undefined && options.container !== undefined ? options.container : 'nb-layout',
            });
            for(let data in objects){
                activeModal.componentInstance[data] = objects[data];
            }
            return activeModal.componentInstance;
        }
    }
}

@Component({
    selector: 'ngx-modal',
    providers: [NumberWithCommas, ImageGallery],
    template: `
      <div class="modal-header">
        <span><b>{{ header || "header not set" }}</b></span>
        <button class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [ngClass]="{ 'image-gallery':type==='image-gallery' }">
        <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'input'">
                <div class="form-group">
                    <div class="col-md-12">
                        <label for="_name">{{inputLabel}}</label>
                        <div class="input-group mb-2">
                        <input type="number" class="form-control" name="defaultInput" [(ngModel)]="defaultInput" maxlength="11" (keyup)="NumberLimit($event)" (blur)="NumberLimit($event)" (click)="NumberLimit($event)">
                        <div class="input-group-append">
                            <button class="btn btn-primary" (click)="dataInput(defaultInput)">Submit</button>
                        </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <ng-container *ngSwitchCase="'image-metadata'">
                <form #f="ngForm" novalidate>
                    <div class="col-md-12">
                        <label for="fname">Image name <span class="text-muted red">*</span></label>
                        <input #name="ngModel" required name="name" type="text" class="form-control" [(ngModel)]="content.name" maxlength="50" placeholder="Enter image name">
                        <small *ngIf="!content.name?.length && name.touched" class="form-text text-muted red float-left required">*required</small>
                        <small class="form-text text-muted float-right">{{ content.name?.length }}/50</small>
                    </div>
                </form>

                <div class="form-group">
                    <div class="col-md-12">
                        <h6 class="form-label-divider top-space">&nbsp;</h6>
                    </div>
                    <button style="min-width:120px;" class="btn btn-success float-right mr-3 mb-3" [nbSpinner]="sgs.submitting" nbSpinnerStatus="success"
                    (click)="sgs.submitting = true; !f.valid || SaveImageMeta()"
                    [disabled]="!f.valid || sgs.submitting">Save</button>
                    <div class="clearfix"></div>
              </div>
            </ng-container>
            
            <ng-container *ngSwitchCase="'image-gallery'">
                <div class="sgs-image-gallery">
                    <div class="sgs-gallery">
                    <div class="sgs-search">
                        <div class="sgs-buttons">
                            <button class="sgs-gallery-button" (click)="getSGSGalleryFileTypes()">File Type</button>
                            <!--- <button class="sgs-gallery-button">Category</button> -->
                            <div class="sgs-gallery-loader"></div>
                            <ng-container *ngFor="let type of sgsgalleryfilter">
                                <button class="sgs-gallery-button type"><i (click)="removeGalleryFilter(type.extension)"></i>{{ type.extension }}</button>
                            </ng-container>
                        </div>
                        <input class="search" type="text" (keyup)="searchImage($event.target.value)" placeholder="Enter image name">
                        <div style="clear:both"></div>
                    </div>
                    <div style="clear:both; margin-bottom:20px;"></div>
                    <div class="sgs-gallery-items">
                        <div *ngIf="!sgsgalleryimages.length && !searchImageString && !sgsinitialloading">
                            No Images found!
                        </div>
                        <div *ngIf="!sgsgalleryimages.length && searchImageString">
                            No Images found for <em>"{{searchImageString}}"</em>
                        </div>
                        <div class="item" *ngFor="let item of sgsgalleryimages">
                            <ng-container *ngIf="['.jpg', '.jpeg', '.png', '.gif'].includes(item.extension)">
                                <div style="position:absolute; top:0;" class="image" *ngIf="item.source" [style.background-image]="item.source | imageGallery : 'image-gallery' : 'css'"></div>
                                <button (click)="SelectedImage(item)">Select</button>
                                <button class="preview" (click)="PreviewImage(item)">View</button>
                            </ng-container>
                            <ng-container *ngIf="['.mp3'].includes(item.extension)">
                                <div class="image notimage" *ngIf="item.source" [style.background-image]="'../assets/images/mp3.png' | imageGallery : '' : 'local-css'"></div>
                                <button (click)="SelectedImage(item)">Select</button>
                                <button class="preview" (click)="PreviewImage(item)">View</button>
                            </ng-container>
                            <ng-container *ngIf="['.mp4'].includes(item.extension)">
                                <div class="image notimage" *ngIf="item.source" [style.background-image]="'../assets/images/mp4.png' | imageGallery : '' : 'local-css'"></div>
                                <button (click)="SelectedImage(item)">Select</button>
                                <button class="preview" (click)="PreviewImage(item)">View</button>
                            </ng-container>
                            <ng-container *ngIf="['.docx', '.doc', '.txt'].includes(item.extension)">
                                <div class="image notimage" *ngIf="item.source" [style.background-image]="'../assets/images/doc.png' | imageGallery : '' : 'local-css'"></div>
                                <button (click)="SelectedImage(item)">Select</button>
                                <button class="preview" (click)="PreviewImage(item)">View</button>
                            </ng-container>
                            <ng-container *ngIf="['.tar', '.zip', '.gz', '.7z', '.rar'].includes(item.extension)">
                                <div class="image notimage" *ngIf="item.source" [style.background-image]="'../assets/images/file.png' | imageGallery : '' : 'local-css'"></div>
                                <button (click)="SelectedImage(item)">Select</button>
                                <button class="preview" (click)="PreviewImage(item)">View</button>
                            </ng-container>
                            <ng-container *ngIf="['.pdf'].includes(item.extension)">
                                <div class="image notimage" *ngIf="item.source" [style.background-image]="'../assets/images/pdf.png' | imageGallery : '' : 'local-css'"></div>
                                <button (click)="SelectedImage(item)">Select</button>
                                <button class="preview" (click)="PreviewImage(item)">View</button>
                            </ng-container>
                        </div>
                    </div>
                    <div class="sgs-pagination">
                        <button [ngClass]="{
                            'sgs-pagination-item' : true,
                            'current':  page.pagenumber === paginationcurrentpage ||
                                        (page.pagenumber+1 === 1 && page?.label === 'PREVIOUS') ||
                                        (page.pagenumber - 1 == page?.pages && page?.label === 'NEXT') }"

                                        *ngFor="let page of pagination"
                                (click)="page.pagenumber !== paginationcurrentpage && getSGSGallery(true, page.pagenumber, page.skip)"
                        >{{ page.label }}</button>
                    </div>
                    
                    </div>
                    <div class="sgs-uploader" (drop)="sgsOnDrop($event)" (dragover)="sgsOnDragOver($event)">
                        <div 
                            [style.background-image]="sgsgallerypreviewurl ?  (sgsgallerypreviewurl | imageGallery : 'image-gallery' : 'css') : ('./assets/images/blank.png' | imageGallery : 'image-gallery' : 'local-css')"
                            [style.background-size]="sgsgallerypreviewurl && 'cover'"
                            [style.height]="sgsgallerypreviewurl ? '315px' : 'auto'"
                            [ngClass]="{ 'uploader-container' : true, 'border' : sgsgallerypreviewurl }"
                        >
                            <div *ngIf="!sgsgallerypreviewurl" class="icon">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <p>Drag file to upload</p>
                            </div>
                            <input type="file" name="file" class="d-none" id="__file" accept="image/x-png,image/gif,image/jpeg">

                            <button id="sgsgallerybutton" (click)="fileUploader($event, '__file')"
                                [style.position]="sgsgallerypreviewurl && 'relative'"
                                [style.top]="sgsgallerypreviewurl ? '305px' : '0'"
                            >Choose File</button>
                        </div>

                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'selector'">
                <ng-container [ngSwitch]="content.type">
                    <ng-container *ngSwitchCase="'array'">
                        <div class="test" *ngFor="let d of content.data">
                            <span class="d">{{ d }}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'array-object'">
                    
                        <div class="input-group" *ngIf="content.search !== undefined && content.search && (content.data.length || searchLoaded)">
                        <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="filterQuery" (keyup)="FilterSearch()">
                        </div>

                        <ng-container [ngSwitch]="accept">
                            <ng-container *ngSwitchCase="'multiple'">
                                <div class="test">
                                    <table class="table"
                                    [style.margin-top]="content.search !== undefined && content.search ? '20px': '0px'">
                                        <ng-container [ngSwitch]="_typeof(content.display)"  *ngIf="content.search !== undefined && content.search">
                                            <ng-container *ngSwitchCase="'object'" >
                                                <thead *ngIf="content.data.length">
                                                    <tr>
                                                        <th
                                                            [style.width]="(100/content.display.length + 1) + '%'"
                                                            *ngFor="let prop of content.display">
                                                            {{ prop.nicename }}
                                                        </th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                            </ng-container>
                                        </ng-container>  
            
                                        <tbody>
                                            <tr *ngFor="let d of content.data.slice(0,8)">
                                                <ng-container [ngSwitch]="_typeof(content.display)">
                                                    <ng-container *ngSwitchCase="'string'">
                                                        <span class="d">{{ d[content.display] }}</span>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'object'">
                                                        <td
                                                            *ngFor="let prop of content.display">
                                                            <ng-container *ngIf="prop.property === '__img__'">
                                                                <img width="24px" src="d[prop.property] | imageGallery : '' : 'local-html'">
                                                            </ng-container>
                                                            <ng-container *ngIf="(prop.property === 'price' || prop.property === 'supplier_price') && prop.property !== '__img__'">
                                                                {{ Nwc(d[prop.property]) }}
                                                            </ng-container>
                                                            <ng-container *ngIf="prop.property !== 'price' && prop.property !== 'supplier_price' && prop.property !== '__img__'">
                                                                {{ d[prop.property] }}
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-primary selected" (click)="multipleSelect(d)">
                                                                {{ d.__selected ? 'Selected' : 'Select' }}
                                                            </button>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Display not supported must be array or string
                                                    </ng-container>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <ng-container *ngIf="!content.data.length && searchLoaded">
                                        No result found for <em style="color:#999">"{{filterQuery}}"</em>
                                    </ng-container>
            
                                    <ng-container *ngIf="!content.data.length && !searchLoaded">
                                        <div [innerHTML]="content.empty?.message"></div><br />
                                        <a *ngIf="content.empty?.buttonName" (click)="closeModal()" class="btn btn-primary selected float-left" [routerLink]="content.empty?.buttonLink">
                                            {{content.empty?.buttonName}}
                                        </a>
                                        <div class="clearfix"></div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'single'">
                                <div class="test">
                                    <table class="table"
                                    [style.margin-top]="content.search !== undefined && content.search ? '20px': '0px'">
                                        <ng-container [ngSwitch]="_typeof(content.display)"  *ngIf="content.search !== undefined && content.search">
                                            <ng-container *ngSwitchCase="'object'" >
                                                <thead *ngIf="content.data.length">
                                                    <tr>
                                                        <th
                                                            [style.width]="(100/content.display.length + 1) + '%'"
                                                            *ngFor="let prop of content.display">
                                                            {{ prop.nicename }}
                                                        </th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                            </ng-container>
                                        </ng-container>  
            
                                        <tbody>
                                            <tr *ngFor="let d of content.data.slice(0,8)">
                                                <ng-container [ngSwitch]="_typeof(content.display)">
                                                    <ng-container *ngSwitchCase="'string'">
                                                        <span class="d">{{ d[content.display] }}</span>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'object'">
                                                        <td
                                                            *ngFor="let prop of content.display">
                                                            <ng-container *ngIf="prop.property === '__img__'">
                                                                <img width="24px" [src]="d[prop.property] | imageGallery : '' : 'local-html'">
                                                            </ng-container>
                                                            <ng-container *ngIf="(prop.property === 'price' || prop.property === 'supplier_price') && prop.property !== '__img__'">
                                                                {{ Nwc(d[prop.property]) }}
                                                            </ng-container>
                                                            <ng-container *ngIf="prop.property !== 'price' && prop.property !== 'supplier_price' && prop.property !== '__img__'">
                                                                {{ d[prop.property] }}
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-primary selected" (click)="Selected(d)">Select</button>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Display not supported must be array or string
                                                    </ng-container>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <ng-container *ngIf="!content.data.length && searchLoaded">
                                        No result found for <em style="color:#999">"{{filterQuery}}"</em>
                                    </ng-container>
            
                                    <ng-container *ngIf="!content.data.length && !searchLoaded">
                                        <div [innerHTML]="content.empty?.message"></div><br />
                                        <a *ngIf="content.empty?.buttonName" (click)="closeModal()" class="btn btn-primary selected float-left" [routerLink]="content.empty?.buttonLink">
                                            {{content.empty?.buttonName}}
                                        </a>
                                        <div class="clearfix"></div>
                                    </ng-container>
                                </div>
                            </ng-container>                            
                        </ng-container>

                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span>'type' not set or type must be 'array' or 'array-object'</span>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <span [innerHTML]="content?content:'content not set'"></span>
            </ng-container>
        </ng-container>
      </div>
      <div class="modal-footer" [ngClass]="{ 'd-none':!showFooter }">

      <ng-container [ngSwitch]="type" *ngIf="showFooter">
            <ng-container *ngSwitchCase="'confirmation'">
                <button class="btn btn-md btn-secondary float-left" (click)="Confirmation(false)">No</button>
                <button class="btn btn-md btn-primary float-right" (click)="Confirmation(true)">Yes</button>
                <div class="clearfix"></div>
            </ng-container>
            <ng-container *ngSwitchCase="'selector'">
                <ng-container *ngIf="accept === 'single'">
                    <button class="btn btn-md btn-primary" (click)="closeModal()">Close</button>
                    <div class="clearfix"></div>
                </ng-container>
                <ng-container *ngIf="accept === 'multiple'">
                    <button class="btn btn-md btn-primary" (click)="closeModal()">Close</button>
                    <button class="btn btn-md btn-success float-right" (click)="emitMultipleSelected()">Add Selected</button>
                    <div class="clearfix"></div>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <button class="btn btn-md btn-primary" (click)="closeModal()">{{ buttonName || 'Ok' }}</button>
            </ng-container>
       </ng-container>

      </div>
    `,
    styles: [`
    .modal-body span{
      line-height: 28px;
    }
    .selected{
        padding: 5px 8px;
        font-size: 11px;
        border-radius: 3px;
        float: right;
        cursor:pointer;
    }
    table{ margin-bottom:0; }
    tbody tr td {
        vertical-align: middle;
        border:none !important;
    }
    tbody tr:hover td{
        background:#e8f1f6 !important;
    }
    tbody tr:nth-child(odd) td {
        background:#f5f5f5;
    }
    tbody tr:nth-child(even) td {
        background:#f9f9f9;
    }
    /deep/ a.badge.badge-primary.pointer {
        color: #fff !important;
    }
    /deep/ a.badge.badge-danger.pointer {
        color: #fff !important;
    }
    /deep/ a.badge.badge-success.pointer {
        color: #fff !important;
    }
    /deep/ a.badge.badge-warning.pointer {
        color: #fff !important;
    }
  `],
  })

export class GlobalModal implements OnInit {

    @Output() public confirm = new EventEmitter();
    @Output() public selected = new EventEmitter();
    @Output() public datainput = new EventEmitter();
    @Output() public selectedImage = new EventEmitter();
    
    public type;
    public accept;
    public showFooter: Boolean = true;
    public header:any;
    /** searching */
    public filterQuery = '';
    public searchLoaded = false;
    public tempSearchData = [];

    /** input */
    public defaultInput;
    public inputLabel;

    /** Selector */
    public selectQueryString = '';
    public content;
    public multipleSelected: any = [];    

    /** start sgs gallery */
    public ImageMeta: any = {};
    public elEventListenerActive: Boolean;
    public searchImageString: any;
    public sgsgalleryfilter: any = [];
    public sgsgallerypreviewurl: any = false;
    public sgsgalleryimages: any = [];
    public sgsinitialloading: any = true;
    public socketInstanceSgsGallery: any;
    public paginationtotal: any = 0;
    public paginationlimit: any = 15;
    public paginationcurrentpage: any = 1;
    public paginationskip: any;
    public pagination: any = [];
    /** sgs gallery end */

    constructor(
        public activeModal: NgbActiveModal,
        public nwc: NumberWithCommas,
        public img: ImageGallery,
        public sgs: SharedGlobalService,
    ) {
        this.sgs.printerEmitter.subscribe( print => {
            this.closeModal();
        });

        // this.initImageMeta();
    }

    ngOnDestroy(){
        if( this.type === 'image-gallery' ){
            this.socketInstanceSgsGallery.unsubscribe();
        }
    }

    async ngOnInit(){
        
        if( this.type === 'image-metadata' ){
            this.ImageMeta = this.content;
        }

        if( this.type === 'image-gallery' ){
            window.addEventListener("dragover", (e:any) => {
                e = e || event;
                e.preventDefault();
            }, false);
            
            window.addEventListener("drop", (e:any) => {
                e = e || event;
                e.preventDefault();
            }, false);
            
            setTimeout(() => {
                this.getSGSGallery(true);
            });

            this.socketInstanceSgsGallery = this.sgs.ResponseSocket('xfile').subscribe( emitted => {
                if(emitted.success){
                  this.getSGSGallery(false);
                }
            });
        }

        if( this.accept === 'multiple' ){
            await this.content.data.map( async a => {
                if( this.content.default !== undefined ){
                    if( this.content.default.data !== undefined ){
                        if(this.content.default.data instanceof Array){
                            await this.content.default.data.map( b => {
                                if( this.content.default.key !== undefined ){
                                    let key = this.content.default.key;
                                    if( a.id === b[key] ){
                                        a.__selected = true;
                                    }
                                }
                            })
                        }else{
                            console.error(`Modal.content.default.data[expecting Array and found "${typeof this.content.default.data}"]`);
                        }
                    }
                }
            });
            await this.content.data.forEach( e => {
                if( e.__selected)
                    this.multipleSelected.push(this.content.return.map(k => e[k] ));
            });
        }
    }
    sgsOnDragOver(e){
        e.preventDefault();
    }
    sgsOnDrop(e){
        e.preventDefault();

        let dt = e.dataTransfer

        let fd = new FormData();
        let file: File = dt.files[0];

        if (file) { //check if image is real image
            var image = new Image();

            image.addEventListener("load", () => {
                fd.append('degree_attachment', file, file.name);
                this.sgs.submitting = true; 
                this.sgs.request('post', 'xfile/galleryImages', fd, response => {
                    if(response.success){
                        this.sgsgallerypreviewurl = response.data.name;
                        document.getElementById('sgsgallerybutton').innerHTML = 'Change';
                        
                        this.ImageMeta.name = response.data.rawFileName;
        
                        this.sgs.Modal({
                            header: `Insert image name`,
                            showFooter: false,
                            buttonName: 'Save',
                            type: 'image-metadata',
                          }, { size: 'sm' });

                    }else{
                        this.sgsgallerypreviewurl = false;
                    }
                });
            });

            image.addEventListener("error", () => {
                this.sgs.Modal({
                    header: `Invalid file`,
                    content: `<b>${file.name}</b> cannot be uploaded. `,
                    buttonName: 'ok'
                }, { size: 'sm' });
            });

            image.src = URL.createObjectURL(file);
        }
    }

    SaveImageMeta(){
        this.sgs.request('put', 'xfile/updateImage', this.ImageMeta, response => {
            // console.log(this.ImageMeta);
            if(response.success){
                this.sgs.Toaster('success', 'Success', response.message);
                
                this.closeModal();
            }
        });
    }


    fileUploader(ev:any, id?:any){
        // console.log(ev);
        let el = document.getElementById(id);
            el.click();

        let handler = (fc) => {
            try{
                let fileList: any;
                let fd = new FormData();
                    if(fc.target['files'][0]['name'] !== undefined){
                    fileList = fc.target;
                    let file: File = fileList.files[0];
                    this.sgsgallerypreviewurl = false;
                    ev.target.innerHTML = 'Change';
                        
                        fd.append('degree_attachment', file, file.name);
                        this.sgs.submitting = true; 
                        this.sgs.request('post', 'xfile/galleryImages', fd, response => {
                            if(response.success){
                                this.elEventListenerActive = false;
                                this.sgsgallerypreviewurl = response.data.name;
                                ev.target.innerHTML = 'Change';
                                el.removeEventListener('change', handler);

                                // console.log(response);
                                // this.ImageMeta.name = response.data.rawFileName;
        
                                this.sgs.Modal({
                                    header: `Insert image name`,
                                    showFooter: false,
                                    buttonName: 'Save',
                                    content: {
                                        id: response.data.id,
                                        name: response.data.rawFileName,
                                    },
                                    type: 'image-metadata',
                                  }, { size: 'sm' });

                            }else{
                                this.sgsgallerypreviewurl = false;
                                ev.target.innerHTML = 'Choose File';
                                el.removeEventListener('change', handler);
                                // this.elEventListenerActive = true;
                                // fileList = undefined;
                            }
                        });

                    }else{
                        this.sgsgallerypreviewurl = false;
                        ev.target.innerHTML = 'Choose File';
                        this.elEventListenerActive = false;
                        el.removeEventListener('change', handler);
                    }
            }catch(e){
                this.sgsgallerypreviewurl = false;
                ev.target.innerHTML = 'Choose File';
                this.elEventListenerActive = false;
                el.removeEventListener('change', handler);
            }
        }
        if( !this.elEventListenerActive ){
            el.addEventListener('change', handler);
            document.getElementById('sgsgallerybutton').innerHTML = 'Choose File';
            this.sgsgallerypreviewurl = false;
            this.elEventListenerActive = true;
        }
    }

    removeGalleryFilter(item){
        return this.sgsgalleryfilter.filter( (result, index) => {
            if( result['extension'] === item ){
                this.sgsgalleryfilter.splice(index, 1);
                this.getSGSGallery(false, 0, 0);
            }
        });
    }

    getSGSGallery(selectorLoader = true, __pagenumber?, __skip?){

        let data = {} as any;
            data.limit = this.paginationlimit;

        if( __pagenumber ){
            this.paginationcurrentpage = __pagenumber;
            this.paginationskip = __skip;

            data = {
                skip: __skip,
                limit: this.paginationlimit
            }
        }else{
            
            data = {
                skip: this.paginationskip,
                limit: this.paginationlimit
            }
        }

        if(this.searchImageString || this.sgsgalleryfilter.length){
            this.paginationcurrentpage = __pagenumber || 1;
            data.query = this.searchImageString;
            data.skip = __skip || 0;
            data.type = this.sgsgalleryfilter.length && this.sgsgalleryfilter;
        }

        this.sgs.request('get', 'xfile/getSGSGallery', data, response => {
            if(response.success){
                this.sgsinitialloading = false;
                this.sgsgalleryimages = response.data.images;
                this.paginationtotal = response.data.total;
                this.initPagination(__pagenumber, __skip);
                // console.table(this.sgsgalleryimages);
            }else{
                this.sgsinitialloading = false;
                this.pagination = [];
                this.sgsgalleryimages = [];
            }
        }, { selectorLoader:selectorLoader,  socketModalLoader:false, toaster:false });
    }

    initPagination(__pagenumber?, __skip?){
        let pages = Math.ceil(this.paginationtotal / this.paginationlimit);

                this.pagination = [];
                let delta = 5;
                let left = this.paginationcurrentpage - delta;
                let right = this.paginationcurrentpage + delta + 1;
                let temp;

                for(let page = 1; page <= pages; page++){
                    if (page == 1 || page == pages || page >= left && page < right) {
                        this.pagination.push({
                            currentpage: __pagenumber || 1,
                            pagenumber: page,
                            label: page,
                            skip: (page - 1) * this.paginationlimit
                        });
                    }
                }
                let nextpage = {
                    currentpage: __pagenumber || 1,
                    pagenumber: this.paginationcurrentpage + 1,
                    label: 'NEXT',
                    pages: pages,
                    skip: this.paginationcurrentpage * this.paginationlimit
                }
                let previouspage = {
                    currentpage: __pagenumber || 1,
                    pagenumber: this.paginationcurrentpage - 1,
                    label: 'PREVIOUS',
                    skip: (this.paginationcurrentpage - 2) * this.paginationlimit
                }

                this.pagination = [previouspage, ...this.pagination, nextpage];

                // this.paginationcurrentpage > 1 && this.pagination;

                pages <= 1 && (this.pagination = []); 
    }

    searchImage(query){
        if( this.searchImageString !== query ){
            this.searchImageString = query.toLowerCase();
            this.getSGSGallery(false, 0 ,0);
        }
    }

    getSGSGalleryFileTypes(){

        this.sgs.request('get', 'xfile/getSGSGalleryFileTypes', {}, response => {
            if(response.success){
                selectFileTypes(response.data);
            }else{
                selectFileTypes([]);
            }
        }, { selectorLoader:true, toaster:false  });

        let selectFileTypes = (data) => {
            this.sgs.Modal({
                header: `Select File Type`,
                showFooter: false,
                content: {
                  type: 'array-object',
                  data: data,
                  display: [
                    { property:'__img__', nicename: "" },
                    { property:'type', nicename: "Type" },
                    { property:'extension', nicename: "Extension" },
                  ],
                  return: ['extension'],
                  empty: {
                    message: 'No file types found',
                  },
                  search: true,
                  searchTo: ['type', 'extension'],
                },
                accept: 'single',
                type: 'selector',
              }, { size: 'md' })
    
              .selected.subscribe( response => {
                this.sgsgalleryfilter.length ?
                    !this.sgsgalleryfilter.filter(e => e['extension'] === response.data[0]).length &&
                        this.sgsgalleryfilter.push({ extension: response.data[0] }) :
                    this.sgsgalleryfilter.push({ extension: response.data[0] });
                    
                    this.getSGSGallery(false, 0 , 0);
              }); 
        }
        
        
    }

    Nwc(num){
        return this.nwc.transform(num, '2');
    }

    FilterSearch(){
        if( !this.searchLoaded ){
            this.tempSearchData = Object.assign([], this.content.data);
            this.searchLoaded = true;
        }

        if( this.content !== undefined){
            if( this.filterQuery.toString() ){
                let temp = this.tempSearchData.filter( e => {
                    return [
                        (() => {
                            let searchTo = [];
                            if( this.content.searchTo !== undefined ){
                                this.content.searchTo.map(prop => {
                                    searchTo.push(e[prop])
                                });
                            }
                            return searchTo;
                        })()
                    ]
                    .join('')
                    .toString()
                    .toLowerCase()
                    .includes(this.filterQuery.toLowerCase());
                });
                this.content.data = (() => {
                    return this.content.sortTo !== undefined?
                        temp.sort( (a, b) => a[this.content.sortTo].length - b[this.content.sortTo].length):
                        temp;
                })();
            } else {
                this.content.data = this.tempSearchData;
            }
        }
    }

    Confirmation(data:boolean){
        this.confirm.emit(data);
        this.closeModal();
    }

    dataInput(data){
        try{
            this.datainput.emit({ success:true, data:data });
            this.closeModal();
        }catch(e){
            this.datainput.emit({ success:false, message: `${e.name} ${e.message}` })
            this.closeModal();
        }        
    }

    multipleSelect(data){
        try{
            let content;
            if(this.content.return !== undefined){
                switch( typeof this.content.return ){
                    case 'string':
                        this.selected.emit({ success:true, data:data[this.content.return] });
                    break;

                    case 'object':
                        if(data.__selected !== undefined){
                            if( data.__selected ){
                                data.__selected = false;
                                this.multipleSelected.map((array, i) => {
                                    if( this.compareArray(array, this.content.return.map(k => data[k] )) ){
                                        this.multipleSelected.splice(i, 1);
                                    }
                                });
                            }else{
                                data.__selected = true;
                                this.multipleSelected.push( this.content.return.map(k => data[k] ) );
                            }
                        }else{
                            data.__selected = true;
                            this.multipleSelected.push( this.content.return.map(k => data[k] ) );
                        }
                    break;

                    default:
                        console.error('data {content.return} type not supported');
                }
                
                // this.closeModal();
            }
        }catch(e){
            this.selected.emit({ success:false, message:e.name + e.message })
            console.error({ success:false, message:e.name + e.message });
            this.closeModal();
        }
    }

    emitMultipleSelected(){
        this.selected.emit({ success:true, data:(() => {
            return this.multipleSelected.map( e => {
              return { id:e[0] }
            })
          })()
        });
        this.closeModal();
    }

    compareArray(compare, array){
        if (!array)
        return false;

        if (compare.length != array.length)
            return false;

        for (var i = 0, l=compare.length; i < l; i++) {
            if (compare[i] instanceof Array && array[i] instanceof Array) {
                if (!compare[i].equals(array[i]))
                    return false;       
            }           
            else if (compare[i] != array[i]) { 
                return false;   
            }           
        }       
        return true;
    }

    PreviewImage(data){
        let extension = data.source.split('.').pop();
        if(['gif', 'jpg', 'png', 'jpeg'].includes(extension)){
          this.sgs.Modal({
            header: `Preview`,
            showFooter: false,
            content: `Name: ${data.name} <br /><img src="${this.img.transform(data.source, 'image-gallery', 'html')}" width="100%">`,
            buttonName: 'close'
          }, { size: 'sm' });
        }else{
          this.sgs.Modal({
            header: `Preview`,
            showFooter: false,
            content: `You can only view image formats.`,
            buttonName: 'close'
          }, { size: 'sm' });
        }
    }
    
    SelectedImage(data){
        try{
            let content;
            if(this.content.return !== undefined){
                switch( typeof this.content.return ){
                    case 'string':
                        this.selectedImage.emit({ success:true, data:data[this.content.return] });
                    break;

                    case 'object':
                        this.selectedImage.emit({ success:true, data:this.content.return.map(k => data[k] ) });
                    break;

                    default:
                        console.error('data {content.return} type not supported');
                }
                
                this.closeModal();
            }
        }catch(e){
            this.selected.emit({ success:false, message:e.name + e.message })
            console.error({ success:false, message:e.name + e.message });
            this.closeModal();
        }
    }

    Selected(data){
        try{
            let content;
            if(this.content.return !== undefined){
                switch( typeof this.content.return ){
                    case 'string':
                        this.selected.emit({ success:true, data:data[this.content.return] });
                    break;

                    case 'object':
                        this.selected.emit({ success:true, data:this.content.return.map(k => data[k] ) });
                    break;

                    default:
                        console.error('data {content.return} type not supported');
                }
                
                this.closeModal();
            }
        }catch(e){
            this.selected.emit({ success:false, message:e.name + e.message })
            console.error({ success:false, message:e.name + e.message });
            this.closeModal();
        }
    }

    NumberLimit(a){
        if( a.target.value && a.target.value.toString().length > a.target.maxLength ){
            // this.Product.markup = a.target.value = a.target.value.slice(0, a.target.maxLength);
            a.target.value = a.target.value.slice(0, a.target.maxLength);
            // console.log(a.target.value);
            return a.target.value;
        }
    }


    _typeof(v){ return typeof v; }

    closeModal() {
      this.activeModal.close();
    }

}
