import { Injectable,  EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { AuthService } from './auth.service';
import { SharedGlobalService } from './shared.global.service';

@Injectable()

export class UserGlobalService implements OnDestroy {
    public $userData:any;
    public webSettings:any;
    socketInstance;
    @Output() public loadData = new EventEmitter();

    constructor(
        private auth:AuthService,
        private sgs:SharedGlobalService,
    ){
        if(this.auth.getTokenData() && this.auth.getTokenData().role){
            this.socketInstance = sgs.ResponseSocket(this.auth.getTokenData().role+'s').subscribe( emitted => {
                if(emitted ){
                    if(!emitted.data.for){
                        this.getUserCollectionData();
                    }else{
                        if(emitted.data.for == 'mobile-maintenance'){
                            this.$userData.maintenance = emitted.data.data.maintenance;
                        }else{
                            this.$userData.update = emitted.data.data.update;
                        }
                    }
                }
            });
        }
    }
  
    public getUserCollectionData(){
        var userData = this.auth.getTokenData();
        var role= '';
        var api='';
        var result:any;
        if(userData && userData.role){
            
            switch(userData.role){
                case 'admin':
                    role = 'admin';
                    api = 'getAdminWithUID';
                break;

                case 'client':
                    role = 'client';
                    api = 'getClientWithUID'; 
                break;

                case 'clientsub':
                    role = 'clientsub';
                    api = 'getClientSubWithUID';
                break;

                case 'consultant':
                    role = 'consultant';
                    api = 'getConsultantWithUID';
                break;

                // case 'customer' : 
                // role = 'customer';
                // api = 'getCustomerWithUID';
                // break;

                default: 
                    role = 'unidentified';
                break;
            }
        }
    
        if(userData && role != 'unidentified'){
            return new Promise(resolve => {
                this.sgs.request('get', role+'/'+api, {id:parseInt(userData.id)}, async (response) => {
                if( response.success ){
                    switch(role){
                        case 'admin':
                            result = response.data;
                        break;

                        case 'client':
                            result = response.data;
                            this.webSettings = response.data.webSettings;
                        break;

                        case 'clientsub':
                            result = response.data;
                            
                        break;

                        case 'consultant':
                            result = response.data[0];
                            this.webSettings = response.data[0].company.webSettings;
                        // console.log(result);
                        break;
                        
                        default:
                            role = 'unidentified';
                        break;
                    }
                    this.$userData = result;
                    return resolve(this.$userData);
                }else{
                    result = null;
                    this.auth.logout();
                }
                },{cache:true, describe: "Error getting clients!" });
            })
        }else{
        // this.auth.logout();
        }
    }
    
    public async userData(){
        return new Promise (promise => {
            if(this.$userData){
                return promise(this.$userData);
            }else{
                this.getUserCollectionData().then(res => {
                    return promise(this.$userData);
                })
            }
        })
    }

    /**
     * 
     * @param ID - User ID in users collection
     * @param role - User role to define which collection it will get.
     */
     public getUserPpsCredentials( ID ){
        return new Promise(resolve => {
            this.sgs.request('get', 'consultant/getConsultantPpsCredentials', { id: parseInt(ID) }, async (response) => {
                if( response.success ){
                    return resolve( { success: true, data: response.data } );
                }else{
                    return resolve( { success: false } );
                }
            })
        })
    }

    ngOnDestroy(){
        this.socketInstance.unsubscribe();
    }
}
