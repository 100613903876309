import { Injectable, Output, EventEmitter } from '@angular/core';
import 'rxjs/add/operator/map';
import { AuthService } from './auth.service';
import { SharedGlobalService } from './shared.global.service';
import { UserGlobalService } from './user.global.service';
import { Http, Headers, RequestOptions } from '@angular/http';
import * as _ from 'lodash';
import { NbWindowService } from '@nebular/theme';

/*
NOTE: This service is indeed for the retrieval of data from the PPS API Bridge. At this service we execute any type
of API calls to have centralize pulling of data.
*/

@Injectable()
export class ApiBridgeGlobalService {
  
    public testHost = "https://damsel.partyplanteam.com/";
    public liveHost = "https://www.myjbloom.com/";

    /* Variables that needs to be broadcast globally */
    $groupsApiData:any;
    $ppsProfile:any;
    percentage:any = 0;
    public userData:any;
    constructor(
        private auth:AuthService,
        private sgs:SharedGlobalService,
        private ugs:UserGlobalService,
        public http: Http,
        public windowService: NbWindowService,
    ){
        this.getUser();
    }

    async getUser(){
        this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
    }

    public resetPpsCache(){
        this.$groupsApiData = undefined;
        this.$ppsProfile = undefined;
    }
    
    /* Use to get the PPS proflie data of the user */
    public getPpsProfile():any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username":  this.ugs.$userData.ppsid,
            "Password":  this.ugs.$userData.ppspassword,
            "APIKey": '',
            "HostName": this.liveHost
        }
        return  new Promise(async resolve => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                await this.sgs.request('get', 'pps/getProfile', { UserModel: credentials }, async (response) => {
                    if(response.success && response.data.Profile){
                      this.$ppsProfile = {success:true, data:response.data.Profile};
                      return resolve({success:true, data:response.data.Profile});
                    }else{
                        this.auth.logout();
                        this.sgs.Toaster('error', 'Invalid Virtual Office Credential Detected', 'Please consider updating your App\'s virtual office credentials to your new one by logging in again.', 10000);
                        return resolve({success:false, data:response.messsage});
                    }
                }, {toaster:false})
                // var request =  this.http.post("https://api.d-solmedia.com/api/Bridge/GetConsultantProfile", { UserModel: credentials }).subscribe(res => {
                //     var response = res.json();
                //     var ppsData = response.Profile;
                //     if(response.ResultCode == 'SUCCESS'){
                //         this.$ppsProfile = response.Profile;
                //         resolve({success:true, data:ppsData});
                //         request.unsubscribe();
                //     }else{
                //         resolve({success:false, data:response});
                //         request.unsubscribe();
                //     }
                // })
            }else{
                return resolve({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

    public validatePpsCredentials(username, password):any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username":  username,
            "Password":  password,
            "APIKey": '',
            "HostName": this.liveHost
        }
        //Having problem in the api connecting to the bridge
        var userData = {
          ppsid:this.ugs.$userData.ppsid,
          uid:this.ugs.$userData.uid
        }

        // console.log(userData);
        return new Promise(promise => {
            this.sgs.request('get', 'pps/getProfile', { UserModel: credentials, userData:userData }, async (response) => {
                if(response.success && response.data.Profile ){
                    return promise({success:true, data:response.data.Profile});
                }else{
                    return promise({success:false, data:response.messsage});
                }
            }, {toaster:false})
        })
        // return new Promise(callback => {
        //     var request =  this.http.post("https://api.d-solmedia.com/api/Bridge/GetConsultantProfile", { UserModel: credentials }).subscribe(res => {
        //         var response = res.json();
        //         var ppsData = response.Profile;
        //         if(response.ResultCode == 'SUCCESS'){
        //             callback({success:true, data:ppsData});
        //             request.unsubscribe();
        //         }else{
        //             callback({success:false, data:response});
        //             request.unsubscribe();
        //         }
        //     })
        // })
    }

    public getPpsStatus( data ) : any{
        console.log(data);
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username":  data.ppsid,
            "Password":  data.ppspassword,
            "APIKey": '',
            "HostName": this.liveHost
        }

        var userData = {
            ppsid: data.ppsid,
            uid: data.ppspassword
        }

        return  new Promise(async promise => {
            this.sgs.request('get', 'pps/getProfile', { UserModel: credentials, userData:userData }, async (response) => {
                console.log('getProfile: ', response )
                if(response.success && response.data.Profile ){
                    return promise({success:true, data:response.data.Profile});
                }else{
                    return promise({success:false, data:response.messsage});
                }
            }, {toaster:false})
        })
    }

/* Use to get the downline consultants of the user */
    public getDownline():any{
        
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username": '',
            "Password": '',
            "APIKey": '',
            "HostName": this.liveHost
        };
        // console.log(this.ugs.$userData);
        if(this.ugs.$userData){
            if(this.ugs.$userData.role == 'clientsub'){
                credentials.Username = this.ugs.$userData.clientppsid;
                credentials.Password = this.ugs.$userData.clientppspassword;
    
                var userData = {
                    ppsid:this.ugs.$userData.clientppsid,
                    uid:this.ugs.$userData.uid
                }
            }else{
                credentials.Username = this.ugs.$userData.ppsid;
                credentials.Password = this.ugs.$userData.ppspassword;
                var userData = {
                    ppsid:this.ugs.$userData.ppsid,
                    uid:this.ugs.$userData.uid
                }
            }
            
            return new Promise( async promise => {
                if((this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword) || (this.ugs.$userData.clientppsid && this.ugs.$userData.clientppspassword)){
                    this.sgs.request('get', 'pps/getDownline', { UserModel: credentials, userData:userData }, async (response) => {
                        if(response.success){
                            // console.log('DIFFERENCE :', );
                            await this.difference(response.data.all)
                            this.$groupsApiData = {success:true, extracted:response.data};
                            return promise({success:true, extracted:response.data});
                        }else{
                            this.sgs.Toaster('error', 'Request Failed', 'Downline request was unsuccessful.')
                        }
                    }, {toaster:false})
                }else{
                    return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
                }
            })
        }
    }

    getAllCompanyConsultants(){
        return new Promise(promise => {
            this.sgs.request('get', 'consultant/getAllConsultants', {skip:0, limit:10000, sort:{lower_fname:1}}, async (response) => {
                if(response.data && response.data.length){
                    return promise(response.data);
                }else{
                    return promise([]);
                }
            })
        })
    }

    difference(downlines){
        return new Promise( async promise => {
            var consultants:any = await this.getAllCompanyConsultants();
            // console.log('ALL CONSULTANTS: ', consultants, 'DOWNLINE CALL RESPONSE :', downlines.length);
            var single = [];
            var multiple = [];
            var noexist = [];
            consultants.map(d => {
                var f = _.filter(downlines, {id:d.uid});
                if(f.length && f.length == 1){
                    single.push({name: d.fname+' '+d.lname, Number:d.ppsid, Sponsor:d.sponsor, id:d.id, found: f});
                }else if(f.length && f.length > 1){
                    multiple.push({name: d.Name, Number:d.Number, Sponsor:d.sponsor, id:d.id, found: f});
                }else{
                    noexist.push({name: d.fname+' '+d.lname, id:d.ppsid});
                }
            })
            // console.log('Single: ', single, 'Multiple: ', multiple, 'NoExist: ', noexist);
            const difference = _.differenceBy(downlines, consultants , 'id');
            return promise(difference);
        })
    }

    //  public getDownline():any{
    //     var credentials = {
    //         "UserType": "DISTRIBUTOR",
    //         "Username": '',
    //         "Password": '',
    //         "APIKey": '',
    //         "HostName": this.liveHost
    //     };
    //     // console.log(this.ugs.$userData);
    //     if(this.ugs.$userData.role == 'clientsub'){
    //         console.log('getting client sub downline');
    //         credentials.Username = this.ugs.$userData.clientppsid;
    //         credentials.Password = this.ugs.$userData.clientppspassword;

    //         var userData = {
    //             ppsid:this.ugs.$userData.clientppsid,
    //             uid:this.ugs.$userData.uid
    //         }
    //     }else{
    //         console.log('getting '+this.ugs.$userData.role+' downline');
    //         credentials.Username = this.ugs.$userData.ppsid;
    //         credentials.Password = this.ugs.$userData.ppspassword;
            
    //         var userData = {
    //             ppsid:this.ugs.$userData.ppsid,
    //             uid:this.ugs.$userData.uid
    //         }
    //     }
    //     return new Promise(callback => {
    //         if((this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword) || (this.ugs.$userData.clientppsid && this.ugs.$userData.clientppspassword)){
    //             var request =  this.http.post("https://api.d-solmedia.com/api/Bridge/GetDSOLDownLine", { UserModel: credentials }).subscribe(async res => {
    //                 var response = res.json();
    //                 console.log(response);
    //                 if(response){
    //                      request.unsubscribe();
    //                      var apiDownlineData = await this.extractPpsData(response.DownLine[0]);
    //                      this.$groupsApiData = apiDownlineData;
    //                      return callback({success:true, extracted: apiDownlineData});
    //                 }else{
    //                     request.unsubscribe();
    //                     return callback({success:false, data:response});
    //                 }
    //             })
    //         }else{
    //             return callback({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
    //         }
    //     })
    // }

    public getUpline():any{
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username": this.ugs.$userData.ppsid,
            "Password": this.ugs.$userData.ppspassword,
            "APIKey": '',
            "HostName": this.liveHost
        };

        var userData = {
            ppsid:this.ugs.$userData.ppsid,
            uid:this.ugs.$userData.uid
        }

        return new Promise(promise => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getUpline', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                        if(response.data.UpLine.length){
                            return promise({success:true, data:response.data.UpLine[0]});
                        }else{
                            return promise({success:false, message:'No Upline Data Found'});
                        }
                    }else{
                        return promise({success:false, message: response.message});
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

    public getAutoLogin(){
        var credentials = {
            "UserType": "DISTRIBUTOR",
            "Username": this.ugs.$userData['ppsid'],
            "Password": this.ugs.$userData['ppspassword'],
            "APIKey": '',
            "HostName": this.liveHost
        };
        var userData = {
            ppsid:this.ugs.$userData.ppsid,
            uid:this.ugs.$userData.uid
        }
        return new Promise(promise => {
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getAutoLogin', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                        return promise({success:true, data:response.data.RedirectUrl});
                    }else{
                        return promise({success:false, message: response.message});
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

    public getPublicEvents():any{
        return new Promise(async promise => {
            var user = this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
            var credentials = {
                "UserType": "PUBLIC",
                "Username": user['ppsid'],
                "Password": user['ppspassword'],
                "APIKey": '',
                "HostName": this.liveHost
            };

            var userData = {
                ppsid:this.ugs.$userData.ppsid,
                uid:this.ugs.$userData.uid
            }
            if(this.ugs.$userData.ppsid && this.ugs.$userData.ppspassword){
                this.sgs.request('get', 'pps/getPublicEvents', { UserModel: credentials, userData:userData }, async (response) => {
                    if(response.success){
                        return promise({success:true, data:response.data});
                    }else{
                        return promise({success:false, message: response.message});
                    }
                }, {toaster:false})
            }else{
                return promise({success:false, data:{Error:'Found empty username or password. Cannot proceed the request.'}});
            }
        })
    }

  /* Execute the call on PPS bridge and has 2 parameters the url and the credentials which use as a key to call. */
    call(url, credentials){
        return this.http.post(url, { UserModel: credentials });
    }

    createPpsGroupEmit(all, rankGroups, groups){
        return new Promise(resolve => {
            var rankGroup = _.groupBy(all, 'Rank');
            var types = _.keysIn(rankGroup);
            var uplines =_.keysIn(_.groupBy(all, 'sponsor'));
            types.map(k => {
                rankGroups.push({groupName: k.toUpperCase(), downLine:rankGroup[k], sponsorIds:uplines});
            });
            var ppsGroups = {
                downlines:groups? groups: 'No data found',
                rankGroups:rankGroups? rankGroups: 'No data found',
                all:all? all: 'No data found'
            }
            return resolve(ppsGroups);
        })
    }

    public checkFromAppDbPromise(consultant){
        // console.log(consultant);
        return new Promise(async resolve => {
            const dbConsultant = await this.getConsultantByPpsId(consultant.Number);
            // console.log(dbConsultant);
            if(dbConsultant){
                var cData = dbConsultant;
                var data = {
                    Name:cData.fname+' '+dbConsultant.lname,
                    id:cData.uid,
                    Rank:consultant.Rank,
                    avatar:cData.profileurl,
                    role:cData.role,
                    Number:consultant.Number,
                    sponsor:consultant.SponsorID,
                    onesignal:cData.onesignal && cData.onesignal.length? cData.onesignal:undefined
                };
                if(consultant.Children && consultant.Children.length){
                    var children = [];
                    var count = 1;
                    // consultant.Children.forEach( async child => {
                    for(let a = 0; a < consultant.Children.length; a++){
                    const child = consultant.Children[a];
                    const childConsultant = await this.getConsultantByPpsId(child.Number);
                    if(childConsultant){
                        // console.log('Authenticated Child', child.Name, 'of '+consultant.Name+'.')
                        var objData = childConsultant;
                        var obj = {
                            Name:objData.fname+' '+objData.lname,
                            id:objData.uid,
                            Rank:child.Rank,
                            avatar:objData.profileurl,
                            role:objData.role,
                            Number:child.Number,
                            sponsor:consultant.SponsorID,

                            onesignal:objData.onesignal && objData.onesignal.length? objData.onesignal:undefined
                        };
                        children.push(obj);
                        if(count == consultant.Children.length){
                            if(children.length){
                            data['Children'] = children;
                            return resolve(data);
                            }else{
                            return resolve(data);
                            }
                        }else{  
                            count++;
                        }
                    }else{
                        if(count == consultant.Children.length){
                            // console.log(count, consultant.Children.length);
                            data['Children'] = children;
                            return resolve(data);
                        }else{  
                            count++;
                        }
                    }
                    }
                }else{
                    return resolve(data);
                }
            }else{
                // console.log(consultant);
                var parent = {
                    Name:consultant.Name,
                    Rank:consultant.Rank,
                    Number:consultant.Number
                };
                if(consultant.Children && consultant.Children.length){
                    // console.log('Not FOUND AT DB BUT STILL HAVE CHILDREN TO CHECK', consultant.Name);
                    var children = [];
                    var count = 1;
                    consultant.Children.forEach(async child => {
                    const childConsultant = await this.getConsultantByPpsId(child.Number);
                    if(childConsultant){
                        // console.log('Authenticated Child', child.Name, 'of '+consultant.Name+'.')
                        var objData = childConsultant;
                        var obj = {
                            Name:objData.fname+' '+objData.lname,
                            id:objData.uid,
                            Rank:child.Rank,
                            avatar:objData.profileurl,
                            role:objData.role,
                            Number:child.Number,
                            sponsor:consultant.SponsorID,
                            onesignal:objData.onesignal && objData.onesignal.length? objData.onesignal:undefined
                        };
                        children.push(obj);
                        if(count == consultant.Children.length){
                            parent['Children'] = children;
                            // console.log(parent);
                            return resolve(parent);
                        }else{  
                            count++;
                        }
                    }else{
                        // console.log('Not FOUND AT DB CHILD', child.Name);
                        if(count == consultant.Children.length){
                            // console.log(parent);
                            if(children.length){
                                parent['Children'] = children;
                                return resolve(parent);
                            }else{
                                return resolve(undefined);
                            }
                        }else{  
                            count++;
                        }
                    }
                    })
                }else{
                    return resolve(undefined);
                }
            }
        })
    }

    extractPpsData(downlineData){
        const callData = downlineData;
        var arrayCandidate = [];
        var all = [];
        var userData = this.ugs.$userData;
        return new Promise(async resolve => {
            arrayCandidate.push(callData);
            while(arrayCandidate.length != 0){
                for(let i= 0; i < arrayCandidate.length; i++ ){
                    if(arrayCandidate[i]['Children'].length){
                        let hasChildArray = await this.getHasChildren(arrayCandidate[i]['Children'], arrayCandidate[i]['Number']);
                        if(hasChildArray.length){
                            hasChildArray.map( a => arrayCandidate.push(a));
                        }
                        if(arrayCandidate[i].userdata){
                            let cData = arrayCandidate[i].userdata;
                            all.push(cData);
                        }
    
                        let hasNoChildArray = await this.getHasNoChildren(arrayCandidate[i]['Children'], arrayCandidate[i]['Number']);
                        if(hasNoChildArray.length){
                            let names = hasNoChildArray.map((c) =>   c.Name+' Number: '+c.Number  );
                            hasNoChildArray.map(c => all.push(c))
                        }
                    }
                }
                try{
                    let downlineGroups = await this.groupByDownline(all, arrayCandidate, userData);
                    let rankGroups = await this.groupByRank(all);
                    var ppsGroups = {
                        downlines:downlineGroups? downlineGroups: 'No data found',
                        rankGroups:rankGroups? rankGroups: 'No data found',
                        all:all? all: 'No data found'
                      }
                    return resolve(ppsGroups);
                }catch(e){
                    console.log(e);
                }
            }
        })
    }

    getHasChildren(array, parentId):any{
        return new Promise(async (promise) => {
            var newArray = [];
            for(let i = 0; i < array.length; i++){
                if(array[i]['Children'].length){
                    array[i].SponsorID = parentId;
                    let promise = await this.getConsultantByPpsid(array[i]);
                    if(promise){
                        array[i].userdata = promise;                    
                    }else{
                        array[i].userdata = undefined;
                    }
                    newArray.push(array[i]);
                }
            }
             return promise(newArray);
        })
    }

    getHasNoChildren(array, parentId):any{
        return new Promise(async promise => {
            var newArray = [];
            for(let i = 0; i < array.length; i++){
                if(!array[i]['Children'].length){
                    array[i].SponsorID = parentId;
                    let promise = await this.getConsultantByPpsid(array[i]);
                    if(promise){
                         newArray.push(promise);
                    }
                }
            }
            return promise(newArray);
       })
    }

    getConsultantByPpsid(c){
        return new Promise(async promise => {
             this.sgs.request('get', 'consultant/getConsultantByPpsid', { id:c.Number }, async (response) => {
                if(response.data && response.data.length){
                    var cData = response.data[0];
                    var obj = {
                        Name:cData.fname+' '+cData.lname,
                        id:cData.uid,
                        Rank:c.Rank,
                        avatar:cData.profileurl,
                        role:cData.role,
                        Number:c.Number,
                        sponsor:c.SponsorID,
                        onesignal:cData.onesignal && cData.onesignal.length? cData.onesignal:undefined
                    };
                    return promise(obj);
                }else{
                    return promise(undefined);
                }
            })
        })
    }

    groupByRank(all):any{
        return new Promise(async (promise) => {
            var rankGroups = [];
            var rankGroup = _.groupBy(all, 'Rank');
            var types = _.keysIn(rankGroup);
            var uplines =_.keysIn(_.groupBy(all, 'sponsor'));
            types.map(k => {
                rankGroups.push({groupName: k.toUpperCase(), downLine:rankGroup[k], sponsorIds:uplines});
            });

            return promise(rankGroups);
        })
    }

    getConsultantByPpsId(id):any{
        return new Promise(resolve => {
            return this.sgs.request('get', 'consultant/getConsultantByPpsid',{id:id}, (res) => {
                if(res && res.data&&  res.data.length){
                    return resolve(res.data[0]);
                }else{
                    return resolve(undefined);
                }
            })
        })
    }

    groupByDownline(all, parents, userData){
        return new Promise(async (promise) => {
            if(parents.length){
                var count = 1;
                var groups = [];
                parents.map(p => {
                    var group = {
                            groupName:p['Name']+' Group', 
                            id:p['Number'], 
                            upLineId:p['SponsorID'], 
                            Rank:p['Rank'], 
                            downLine:[], 
                            uid:p.userdata? p.userdata.uid:userData.uid
                        };
                    var dl =  _.filter(all, {'sponsor':p.Number});
                    if(dl.length){
                        // console.log('Has Downline from ALL: ', p);
                        if(p.Number == userData.ppsid){
                            group.groupName = 'My Group';
                        }
                        group.downLine = dl;
                        groups.push(group);
                    }
                    if(count == parents.length){
                        return promise(groups);
                    }else{
                        count++;
                    }
                })
          
            }else{
                return promise([]);
            }
        })
    }

    toastLoader(percent){
        this.percentage = percent;
    }
}


