import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { UserGlobalService } from '../../../@core/services/user.global.service';
@Component({
  selector: 'ngx-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
    keyword = '';
    posts = [];
    notifications = [];
    uncategorizedLeads = [];
    hotLeads = [];
    coldLeads = [];
    courses = [];
    optionalCourses = [];
    consultants = [];
    customers = [];
    groups = [];
    resources = [];
    motivators = [];
    role: string = '';
    userId:Number;
    modules: undefined;
    constructor(
        public sgs: SharedGlobalService,
        public ugs: UserGlobalService,
        private route: ActivatedRoute,
        private router: Router
    ){
        this.userId = this.ugs.$userData.role == 'client' ? this.ugs.$userData.uid : this.ugs.$userData.role == 'clientsub' ? this.ugs.$userData.companyid: this.ugs.$userData.uid;
        this.modules = this.ugs.$userData.role == 'client' || this.ugs.$userData.role == 'clientsub'?  this.ugs.$userData.webSettings.moduleDisplay : this.ugs.$userData.company.webSettings.moduleDisplay;
        // console.log(this.modules)
        this.role = this.ugs.$userData.role;
        this.route.params.subscribe(params => {
            this.keyword = params.keyword;
            this.posts = [];
            this.notifications = [];
            this.uncategorizedLeads = [];
            this.hotLeads = [];
            this.coldLeads = [];
            this.courses = [];
            this.optionalCourses = [];
            this.consultants = [];
            this.customers = [];
            this.groups = [];
            this.resources = [];
            this.motivators = [];
            this.globalSeearchPosts();
            this.globalSearchNotifications();
            this.globalSearchUncategorizedLeads();
            this.globalSearchHotLeads();
            this.globalSearchColdLeads();
            this.globalSearchCourses();
            this.globalSearchOptionalCourses();
            this.globalSearchConsultants();
            this.globalSearchCustomers();
            this.globalSearchGroups();
            this.globalSearchResources();
            this.globalSearchMotivators();
        })
    }

    ngOnDestroy(){}

    ngOnInit(){}

    globalSeearchPosts(){
        this.sgs.request('get', 'post/globalSeearchPosts', { value: this.keyword.toLowerCase() }, async (response) => {
            if(response.success && response.data)   this.posts =  response.data;
        })
    }

    globalSearchNotifications(){
        var senderRoles = ['client', 'clientsub'];
        this.sgs.request('get', 'notification/globalSearchNotifications', { text: this.keyword.toLowerCase(), role:senderRoles}, async (response) => {
            if(response.success && response.data)   this.notifications =  response.data;
        })
    }

    globalSearchUncategorizedLeads(){
        this.sgs.request('get', 'customer/globalSearchUncategorizedLeads', { text: this.keyword.toLowerCase(), id:this.userId }, async (response) => {
            if(response.success && response.data)   this.uncategorizedLeads =  response.data;
        })
    }

    globalSearchCustomers(){
        this.sgs.request('get', 'customer/globalSearchCustomers', { text: this.keyword.toLowerCase(), id:this.userId }, async (response) => {
            if(response.success && response.data)   this.customers =  response.data;
        })
    }

    globalSearchHotLeads(){
        this.sgs.request('get', 'customer/globalSearchHotLeads', { text: this.keyword.toLowerCase(), id:this.userId }, async (response) => {
            if(response.success && response.data)   this.hotLeads =  response.data;
        })
    }

    globalSearchColdLeads(){
        this.sgs.request('get', 'customer/globalSearchColdLeads', { text: this.keyword.toLowerCase(), id:this.userId }, async (response) => {
            if(response.success && response.data)   this.coldLeads =  response.data;
        })
    }

    globalSearchCourses(){
        this.sgs.request('get', 'course/globalSearchCourses', { value: this.keyword.toLowerCase() }, async (response) => {
            if(response.success && response.data)   this.courses =  response.data;
        })
    }

    globalSearchOptionalCourses(){
        this.sgs.request('get', 'course/globalSearchOptionalCourses', { value: this.keyword.toLowerCase() }, async (response) => {
            if(response.success && response.data)   this.optionalCourses =  response.data;
        })
    }

    globalSearchConsultants(){
        this.sgs.request('get', 'consultant/globalSearchConsultants', { text: this.keyword.toLowerCase() }, async (response) => {
            if(response.success && response.data)   this.consultants =  response.data;
        })
    }

    globalSearchGroups(){
        this.sgs.request('get', 'group/globalSearchGroups', { text: this.keyword.toLowerCase(), id: this.userId }, async (response) => {
            if(response.success && response.data)   this.groups =  response.data;
        })
    }

    globalSearchResources(){
        this.sgs.request('get', 'resource/globalSearchResources', { value: this.keyword.toLowerCase() }, async (response) => {
            if(response.success && response.data)   this.resources =  response.data;
        })
    }

    globalSearchMotivators(){
        this.sgs.request('get', 'motivator/globalSearchMotivators', { value: this.keyword.toLowerCase() }, async (response) => {
            if(response.success && response.data)   this.motivators =  response.data;
        })
    }
}
