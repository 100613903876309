import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGlobalService } from '../../user.global.service';
import { SharedGlobalService } from '../../shared.global.service';
import { NbWindowService } from '@nebular/theme';
import { ApiBridgeGlobalService } from '../../api-bridge.global.service';
@Component({
  selector: 'ngx-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  percentage = 0.05 * 100;
  constructor(
    public sgs: SharedGlobalService,
    public api:ApiBridgeGlobalService,
    private fb: FormBuilder,
    private ugs: UserGlobalService,
    public windowService: NbWindowService
  ) {
   }

  ngOnInit() {
  //  this.api.downlineProgress.subscribe(res => {
  //    console.log('entering emit')
  //     this.percentage = res * 100;
  //  })
  }
 

}
