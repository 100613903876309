import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'ngx-consultant-details',
  templateUrl: './consultant-details.component.html',
  styleUrls: ['./consultant-details.component.scss']
})
export class ConsultantDetailsComponent implements OnInit {

  bsValue = new Date();
  filterQuery = '';
  sortBy = 'id';
  sortOrder = 'asc';
  selectQueryString = 'Last Name';
  selectQuery = 'lname';
  data;
  loading = true;
  date = new Date();
  todate;
  private socketInstance;
  private noteInstance;
  id:any;
  logourl:any = '';
  profileurl:any='';
  haschanged:boolean;
  isAddNote:boolean;
  notes:any;
  note:any;
  isEdit:boolean;
  forEdit:any;
  basicInfo:FormGroup;
  isAdminUser:boolean;
  isTestUser:boolean;
  isSubmit:any;
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.socketInstance = sgs.ResponseSocket('consultants').subscribe( emitted => {
      //on emit; false
      this.getConsultant();
    });
    this.noteInstance = sgs.ResponseSocket('notes').subscribe( emitted => {
      //on emit; false
      if(emitted.success && emitted.data == this.id){
        this.getNotes();
      }
    });
   }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.params['id']);
    //console.log(this.id)
    this.getConsultant();
   

  }
  ngOnDestroy(){
    this.socketInstance.unsubscribe();
    this.noteInstance.unsubscribe();
  }

   getConsultant(){
      this.sgs.request('get', 'consultant/getConsultantWithID', {id:this.id}, async (response) => {
      if( response.success ){
          this.data = response.data[0];
          this.isAdminUser = this.data.isAdminUser? true:false;
          this.isTestUser = this.data.isTestUser? true:false;
          this.getNotes();
          this.loading = false;
      }else{
      }
    },{cache:true, describe: "Error getting consultants!" });
  }

  elEventListenerActive: boolean;

  openFile(ev, id){
    var url:any;
    
    let file,
        el = document.getElementById(id);
        el.click();


        let handler = (fc) => {
          try{
        let fileList: any;
        let fd = new FormData();
            if(fc.target['files'][0]['name'] !== undefined){
              fileList = fc.target;
              let file: File = fileList.files[0];
              url = file.name;
              // ev.target.innerHTML = 'Change';
              
                fd.append('degree_attachment', file, file.name);

                this.sgs.request('post', 'xfile/profileImage', fd, response => {
                  if(response.success){
                    this.elEventListenerActive = false;
                    url = response.data.name;
        
                      this.data.profileurl = url;
                      this.haschanged = true;
                    
                    el.removeEventListener('change', handler);
                    this.sgs.Modal({
                      header: `Image successfully uploaded`,
                      content: `
                        <b>Image name:</b> ${file.name} <br />
                        <b>Renamed by server to:</b> ${response.data.name} <br /><br />
                        <img width="100%" src="${this.sgs.connection}/images/${response.data.name}">
                      `,
                      buttonName: 'close'
                    }, { size: 'md' });
                  }else{
                    url = '';
                    el.removeEventListener('change', handler);
                  }
                });


            }else{
              url = '';
              ev.target.innerHTML = 'Browse';
              this.elEventListenerActive = false;
              el.removeEventListener('change', handler);
            }
          }catch(e){
            url = '';
            ev.target.innerHTML = 'Browse';
            this.elEventListenerActive = false;
            el.removeEventListener('change', handler);
          }
        }
        
        if( !this.elEventListenerActive ){
          el.addEventListener('change', handler);
          this.elEventListenerActive = true;
        }
  }

  editBasicInfo(){
    this.basicInfo  = this.fb.group({
      fname: [this.data.fname, [Validators.required, Validators.minLength(2)]],
      lname: [this.data.lname, [Validators.required, Validators.minLength(2)]],
      contact: [this.data.contact],
      address: [this.data.address],
      email: [this.data.email, [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
    })
    this.isEdit = true;
  }

  getNotes(){
    var data = {
      id:this.id,
      companyid:this.data.company.companyid,
      cid:0,
    }
    this.sgs.request('get', 'note/getNotes', data, async (response) => {
      if( response.success ){
          this.notes = response.data;
      }else{
      }
    },{cache:true, describe: "Error getting consultants!" });
  }

  addNote(){
    var obj = {
      consultantId:this.id,
      note:this.note,
      customerId: 0,
      date_added: new Date,
      isActive:true,
      companyid:this.data.company.companyid
    }
    
    this.sgs.request('post', 'note/createNote', obj, res => {
      if(res.success){
        this.sgs.Toaster('success', 'Success', 'Note was created successfully.');
        this.isAddNote = false;
        this.note = '';
      }
    })
  }

  updateNote(){
    this.forEdit.note = this.note;
    // console.log(this.forEdit);
    this.sgs.request('post', 'note/updateNote', this.forEdit, res => {
      if(res.success){
        this.sgs.Toaster('success', 'Success', 'Note was updated successfully.');
        this.isEdit = false;
        this.forEdit = {};
      }
    })
  }

  remove(n){
    if(confirm('Are you sure you want to remove this note for this consultant?')){
      this.sgs.request('post', 'note/deleteNote', n, res => {
        if(res.success){
          this.sgs.Toaster('success', 'Success', 'Note was removed successfully.');
        }
      })
    }
  }
  
  edit(n){
    this.note = n.note;
    this.isEdit = true;
    this.forEdit = n;
  }
  back(){
    if(this.haschanged){
      if(confirm('Are you sure you want to leave the page without saving?')){
        this.router.navigate(['/client/consultants/all-consultants']);
      }
    }else{
      this.router.navigate(['/client/consultants/all-consultants']);
    }
  }

  submit(){
    if(this.isEdit){
      this.data.fname = this.basicInfo.value.fname;
      this.data.lname = this.basicInfo.value.lname;
      this.data.contact = this.basicInfo.value.contact;
      this.data.address = this.basicInfo.value.address;
      this.data.email = this.basicInfo.value.email;
    }
    this.sgs.request('post', 'consultant/updateConsultant', this.data, async (response) => {
      if(response.success){
      this.sgs.Toaster('success', 'Success', 'Consultant information was updated successfully.');
       this.isEdit = false;
       this.haschanged = false;
      }
    }, { cache:true, describe: "ERROR: Consultant update" })
  }

  assignAdminUser(){
    if(confirm('Are you sure you want to assign this consultant as an admin user of the app?')){
      this.isSubmit = true;
      this.data.isAdminUser = true;
      this.sgs.request('post', 'consultant/toogleAdminUser', this.data, async (response) => {
        if(response.success){
          this.isAdminUser = true;
          this.isSubmit = false;
          this.sgs.Toaster('success', 'Success', 'Consultant was assigned as admin user successfully.');
        }
      })
    }
  }

  deAssignAdminUser(){
    if(confirm('Are you sure you want to remove this consultant as admin user of the app?')){
      this.isSubmit = true;
      this.data.isAdminUser = false;
      this.sgs.request('post', 'consultant/toogleAdminUser', this.data, async (response) => {
        if(response.success){
          this.isAdminUser = false;
          this.isSubmit = false;
          this.sgs.Toaster('success', 'Success', 'Consultant was disabled as admin user successfully.');
        }
      })
    }
  }

  assignTestUser(){
    if(confirm('Are you sure you want to assign this consultant as test user of the app?')){
      this.isSubmit = true;
      this.data.isTestUser = true;
      this.sgs.request('post', 'consultant/toogleTestUser', this.data, async (response) => {
        if(response.success){
          this.isTestUser = true;
          this.isSubmit = false;
          this.sgs.Toaster('success', 'Success', 'Consultant was assigned as admin user successfully.');
        }
      })
    }
  }

  deAssignTestUser(){
    if(confirm('Are you sure you want to remove this consultant as test user of the app?')){
      this.isSubmit = true;
      this.data.isTestUser = false;
      this.sgs.request('post', 'consultant/toogleTestUser', this.data, async (response) => {
        if(response.success){
          this.isTestUser = false;
          this.isSubmit = false;
          this.sgs.Toaster('success', 'Success', 'Consultant was disabled as admin user successfully.');
        }
      })
    }
  }

}
