import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../@core/services/auth.service';
import { UserGlobalService } from '../@core/services/user.global.service';

@Injectable()
export class ModuleGuard implements CanActivate {
    constructor(
        private authService: AuthService, 
        private router: Router,
        public ugs:UserGlobalService
        )
    {
      
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let data = await this.ugs.getUserCollectionData();
        try{
            if (data) {
                if(data['company'].webSettings.moduleDisplay[route.routeConfig.path]){
                    return true;
                }else{
                    this.router.navigate(['/consultant/404']);
                }
            }
            else {
                this.authService.logout();
                return false;
            }
        }catch(err){
            console.log(err);
        }

    }

 

}
