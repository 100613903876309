import { Injectable, Output, EventEmitter } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { UserGlobalService } from './user.global.service';
// import 'rxjs/add/operator/map';


@Injectable()
export class AuthService {
  public user: any;
  public authToken: any;
  public options: any;
  testHost = "https://damsel.partyplanteam.com/";
  liveHost = "https://www.mydamselpro.net/";
  credentials:any; 
  GetConsultantProfileUrl = "https://api.d-solmedia.com/api/Bridge/GetConsultantProfile";
  @Output() public userLogout = new EventEmitter();
  @Output() public userLogin = new EventEmitter();
  constructor(
    public http: Http,
    public router: Router,
    public location: Location,
    // public ugs: UserGlobalService,

  ){}

  /* PPS Authentication Check */

  checkFromPPS(data, callback){
    // console.log(data);
    this.credentials= {
      "UserType": "DISTRIBUTOR",
      "Username": data.ppsid,
      "Password": data.ppspassword,
      "APIKey": "48FBF98A-C35F-4F05-90E6-FFDE8107E1FA",
      "HostName": this.liveHost
    };
      this.getProfileAPI(this.credentials).subscribe(res => {
       
        var response = res.json();
        var ppsData = response.Profile;
        // console.log(response);
        // console.log(ppsData)
        if(response.ResultCode == 'SUCCESS'){
          return callback({success:true,data:ppsData});
        }else{
          return callback({success:false,data:response});
        }
      })
  }

  getProfileAPI(credentials){
      return this.http.post(this.GetConsultantProfileUrl, { UserModel: credentials });
  }

  // getPpsCredentials(username, password, callback){
  //   this.credentials = {
  //     "UserType": "DISTRIBUTOR",
  //     "Username": username,
  //     "Password":password,
  //     "APIKey": "48FBF98A-C35F-4F05-90E6-FFDE8107E1FA",
  //     "HostName": this.liveHost
  //   };

  //   this.getProfileAPI(this.credentials).subscribe(res => {
  //     var response = res.json();
  //     var ppsData = response.Profile;
  //     if(response.ResultCode == 'SUCCESS'){
  //       return callback(ppsData);
  //     }else{
  //       return callback(false);
  //     }
  //   })
  // }

  /* END OF PPS AUTHENTICATION */

  Headers(xdata?){
    return xdata?
      new RequestOptions({
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization': this.getToken(),
          'xdata': xdata
        }),
      }):
      new RequestOptions({
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization': this.getToken(),
        }),
      });
  }

 /**
 * @param {String} data server payload / token
 * @return {Promise} Boolean true or false; setting tokens
 */
  public setToken(data){
    return new Promise( (resolve, reject) => {
        try{
          if (typeof(Storage) !== "undefined") {
            for(let prop in data) {
              let
                type = typeof data[prop],
                context = type.toLowerCase() === 'string'?
                data[prop] : JSON.stringify(data[prop]);
                localStorage.setItem(prop, context)
            }
            resolve(true);
          }else{
            reject(false);
          }
        }catch(e){
          console.info(e);
          reject(false);
        }
    });
  }

  /**
   * @session {String} new session id
   */
  updateLocalStorageSession(session){
    console.log('New Session: ', session);
    return new Promise(resolve => {
        if(session){
          const data = JSON.parse(localStorage.getItem('user'))
          data.session_id = session;
          localStorage.setItem('user', JSON.stringify(data));
        }
    })
  }


  /**
   * @return {String} token
   */
  public getToken(){
    return localStorage.getItem('token');
  }
  
  /**
   * @return {Boolean} if user has token and equals to role
   * true or false
   */
  public hasToken(){
    return this.getToken()?(()=>{this.Observer();return true;})():false;
  }

  /**
   * @return {Void} 
   * @description observes routes by role
   */
  public Observer() : void{
    let role = this.getTokenData('role');
    let self = this.router.events.subscribe(nextUrl => {
      if(nextUrl instanceof NavigationEnd){
        if(this.location.path().split('/')[1] !== role){
          self.unsubscribe();
          this.router.navigate([role]);
        }
      }
    });
  }

  /**
   * @param {String} key any object property of signed user
   * @return {String} key value
   */
  public getTokenData(key?){
    try{
      if( this.hasToken ){
        let token = JSON.parse(atob(this.getToken().split('.')[1]));
        if(key){
          switch(typeof key){
            case 'string':
              return token[key]
            case 'object':
              return key.map(k => token[k] );
          }
        }else{ return token; }
      }
    }catch(e){
      // this.logout();
      //console.info('Error:', e.message);
    }
  }

  
  /**
   * @return {Void}
   * @description clear token & user then navigate login
   */
  public logout() : void{
    this.userLogout.emit();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigate(['/login']);

  }
  
  public login(){
    this.userLogin.emit()
    this.router.navigate([this.getTokenData('role')]);
  }

  

}
