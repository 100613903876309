import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ReportsModalComponent } from '../modals/reports/reports-modal.component';


@Component({
  selector: 'ngx-client-users',
  templateUrl: './client-users.component.html',
  styleUrls: ['./client-users.component.scss']
})
export class ClientUsersComponent implements OnInit {

  bsValue = new Date();
  filterQuery = '';
  sortBy = 'id';
  sortOrder = 'asc';
  selectQueryString = 'Last Name';
  selectQuery = 'lname';
  selectedRole = 'Consultants';
  selectedRoleQuery ="consultant";
  data;
  loading = true;
  date = new Date();
  todate;
  rowsOnPage = 10;
  id:any;
  private socketInstance;
  name:any;
  selections = [
    {display:'Last Name', value:'lname'},
    {display:'First Name', value:'fname'},
    {display:'Email', value:'email'},
  ]

  rolesSelection = [
    {display:'Consultants', value:'consultant'},
    {display:'Customers', value:'customer'},
  ]

  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    public route: ActivatedRoute,
  ) {
    this.name = this.route.snapshot.params['name'];
    this.id = this.route.snapshot.params['id'];
    this.socketInstance = sgs.ResponseSocket('clients').subscribe( emitted => {
      this.getAllUsers(false);
    });
   }

  ngOnInit() {
    this.getAllUsers();
  }

  ngOnDestroy(){
    this.socketInstance.unsubscribe();
  }

  getAllUsers(socketLoader = true){
    switch(this.selectedRoleQuery){
      case 'consultant':
        this.sgs.request('get', 'consultant/getAllCompanyConsultants', {companyid:parseInt(this.id)}, async (response) => {
          if( response.success ){
              this.data = response.data;
          }else{
            this.data = [];
          }
        },{ socketLoader:socketLoader, cache:true, describe: "Error getting clients!" });
      break;
      case 'customer':
        this.sgs.request('get', 'customer/getAllCompanyCustomers', {companyid:parseInt(this.id)}, async (response) => {
          if( response.success ){
              this.data = response.data;
          }else{
            this.data = [];
          }
        },{ socketLoader:socketLoader, cache:true, describe: "Error getting clients!" });
      break;

      default:
        console.log('Unknown Role for query.')
      break;
    }

  }


  selectFilter(data){
    this.selectQuery = data.value;
    this.selectQueryString = data.display;
  }

  selectRole(data){
    this.selectedRoleQuery = data.value;
    this.selectedRole = data.display;
    this.getAllUsers();
  }

  openReportsModal(){
    const activeModal = this.ngbModal.open(ReportsModalComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
    activeModal.componentInstance.id = this.id;
  }


  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }

  changeStatus(id,uid,companyname,status){
   var stat =  status? 'Activate Client':'Deactivate Client';
   var newStatus = !status;
   var message = status? 'Client has been activated':'Client has been deactivated';
   var content = status? 'Are you sure to activate <b> '+companyname+' </b>?': 'Are you sure to deactivate <b> '+companyname+' </b>?';
   
    this.sgs.Modal({
      header: stat,
      content: content,
      type: 'confirmation',
    }, { size: 'sm' })
    .confirm.subscribe( response => {
      if( response ){
        this.sgs.request('post', 'consultant/deactivateConsultant', {id:id, status:newStatus}, response => {
          if(response){
            this.sgs.request('post', 'user/deactivateUser', {id:uid, status:newStatus}, response => {
              this.sgs.Toaster('success', 'Success', message);
            })
          }
        })
      }
    });
  }
  deleteClient(id,companyname,status){
    this.sgs.Modal({
      header: 'Delete Client',
      content: 'Are you sure you want to delete <b> '+companyname+' </b>?',
      type: 'confirmation',
    }, { size: 'sm' })
    .confirm.subscribe( response => {
      if( response ){
        this.sgs.request('post', 'client/deleteClient', {id:id, status:2}, response => {
          if(response){
            this.sgs.Toaster('success', 'Success', 'Client has been deleted successfully');
          }
        })
      }
    });
  }
  

  disableAccount(id){
    var answer = confirm("Deactivate user account?")
    if (answer) {
      this.sgs.request('post', 'user/deactivateUser', {id: id}, async (response) => {
        this.sgs.Toaster('warning', 'Warning', 'The user has been deactivated!');
      })
    }
  }

  enableAccount(id){
    var answer = confirm("Activate user account?")
    if (answer) {
      this.sgs.request('post', 'user/enableUser', {id: id}, async (response) => {
        this.sgs.Toaster('success', 'Success', 'The user has been activated!');
      })
    }
  }

}
