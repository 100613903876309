import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../@core/services/shared.global.service';
import { AuthService } from '../../@core/services/auth.service';


@Component({
  selector: 'ngx-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user:any;
  data:any;
  isEdit:boolean;
  showPass:boolean;
  isChangeProfile:boolean;
  isChangeLogo:boolean;
  isTinyTouch:boolean;
  defaultSignature:string;
  private socketInstance;
  
  constructor(
    public sgs: SharedGlobalService,
    private auth:AuthService
  ){
    this.socketInstance = sgs.ResponseSocket('admins').subscribe( emitted => {
      //on emit; false
      this.getAdmin(false);
    });
  }

  ngOnDestroy(){
    this.socketInstance.unsubscribe();
  }

  ngOnInit() {
   
    this.prepareDefault();
    this.getAdmin();
  }

  prepareDefault(){
    this.user = this.auth.getTokenData();
  }

  getAdmin(socketLoader = true){
    if(this.user){
      this.sgs.request('get', 'admin/getAdminWithID', {id:this.user.id}, async (response) => {
        if( response.success ){
            this.data = response.data;

            this.data.signature? null: this.defaultSignature = this.data.signature || this.data.fname +' '+ this.data.lname+'<br><b>DSOL ADMIN</b><br>';
        }else{
          console.log( response );
        }
      },{cache:true, describe: "Error getting admins!" });
    }
  
}

log($event){
  console.log($event);
}

elEventListenerActive: boolean;

openFile(ev, id, purpose){
  purpose == 'profileImage'?  this.isChangeProfile = true: this.isChangeLogo = true;
  let file,
      el = document.getElementById(id);
      el.click();


      let handler = (fc) => {
        try{
      let fileList: any;
      let fd = new FormData();
          if(fc.target['files'][0]['name'] !== undefined){
            fileList = fc.target;
            let file: File = fileList.files[0];
           
            purpose == 'profileImage'?   this.data.profileurl = file.name:  this.data.logourl = file.name;
            // ev.target.innerHTML = 'Change';
            
              fd.append('degree_attachment', file, file.name);
             
             
              console.log('Action:'+ purpose);
              this.sgs.request('post', 'xfile/'+purpose, fd, response => {
                if(response.success){
                  this.elEventListenerActive = false;
                  purpose == 'profileImage'?   this.data.profileurl = response.data.name:  this.data.logourl = response.data.name;
                //  this.adminForm.patchValue({logourl:response.data.name});
                  
                  el.removeEventListener('change', handler);
                  this.sgs.Modal({
                    header: `Image successfully uploaded`,
                    content: `
                      <b>Image name:</b> ${file.name} <br />
                      <b>Renamed by server to:</b> ${response.data.name} <br /><br />
                      <img width="100%" src="${this.sgs.connection}/images/${response.data.name}">
                    `,
                    buttonName: 'close'
                  }, { size: 'md' });
                }else{
                  purpose == 'profileImage'?   this.data.profileurl = '':  this.data.logourl = '';
                  el.removeEventListener('change', handler);
                }
              });


          }else{
            this.isChangeProfile = false;
            purpose == 'profileImage'?   this.data.profileurl = '':  this.data.logourl = '';
            ev.target.innerHTML = 'Browse';
            this.elEventListenerActive = false;
            el.removeEventListener('change', handler);
          }
        }catch(e){
          purpose == 'profileImage'?   this.data.profileurl = '':  this.data.logourl = '';
          this.isChangeProfile = false;
          ev.target.innerHTML = 'Browse';
          this.elEventListenerActive = false;
          el.removeEventListener('change', handler);
        }
      }
      
      if( !this.elEventListenerActive ){
        el.addEventListener('change', handler);
        this.elEventListenerActive = true;
      }
}

cancelChangeProfile(){
  if(confirm('Are you sure you want to cancel the new profile picture that you are about to save?')){
    this.data.profileurl = '';
    this.isChangeProfile = false;
  }
}

cancelChangeLogo(){
  if(confirm('Are you sure you want to cancel the new company logo picture that you are about to save?')){
    this.data.logourl = '';
    this.isChangeLogo = false;
  }
}

saveChanges(purpose){
  this.sgs.request('post', 'admin/updateAdmin', this.data, async (response) => {
    if(response.success){
      switch(purpose){
        case 'profilepic':
          this.sgs.Toaster('success', 'Success', 'Admin Profile Image has been updated successfully.');
          this.cancelChangeProfile();
        break;

        case 'profileinfo':
          this.sgs.Toaster('success', 'Success', 'Admin Profile Information has been updated successfully.');
            this.sgs.request('post', 'user/updateUserCollectionFromAdmin', this.data, (response));
          this.isEdit = false;
        break;

        case 'signature':
        
        break;
    
        default:
        console.log('Nothing to be save...');
        break;
      }
    }
  }, { cache:true, describe: "ERROR: Admin update" })
}

write(ev){
  this.isTinyTouch = true;
  this.data['signature'] = ev;
  console.log(ev);
}

resetTiny(){
  this.isTinyTouch = false;
}
  
}
