import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { dataFilterPipe, tagsDataFilterPipe, searchDataFilterPipe, multiFilterPipe, NumberWithCommas, ImageGallery, CountByValue, ReverseDate } from '../@core/pipes/dataFilter';
import { DataTableModule } from 'angular2-datatable';
import { NbStepperModule, NbSpinnerModule, NbListModule, NbCalendarModule,  NbAccordionModule } from '@nebular/theme';
import { MomentModule } from 'angular2-moment';
import { QRCodeModule } from 'angularx-qrcode';
import { TruncateModule } from 'ng2-truncate';
// from valor components
import { TooltipModule, BsDatepickerModule, TimepickerModule  } from 'ngx-bootstrap';
import { TruncatePipesModule } from 'angular-truncate-pipes';
import { YoutubePlayerModule } from 'ngx-youtube-player';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerModalComponent } from './spinner-modal/spinner-modal.component';

@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    NbStepperModule,
    NbSpinnerModule,
    NbCalendarModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TruncatePipesModule,
    YoutubePlayerModule,
    MomentModule,
    TruncateModule,

  ],
  declarations: [
    dataFilterPipe,
    tagsDataFilterPipe,
    searchDataFilterPipe,
    SpinnerComponent,
    SpinnerModalComponent,
    NumberWithCommas,
    multiFilterPipe,
    ImageGallery,
    CountByValue,
    ReverseDate
  ],
  entryComponents: [
    SpinnerModalComponent
  ],
  exports: [
    dataFilterPipe,
    tagsDataFilterPipe,
    searchDataFilterPipe,
    DataTableModule,
    NbStepperModule,
    NbSpinnerModule,
    BsDatepickerModule,
    TimepickerModule,
    NbListModule,
    MomentModule,
    NbAccordionModule,
    TooltipModule,
    SpinnerComponent,
    TruncateModule,
    SpinnerModalComponent,
    NumberWithCommas,
    multiFilterPipe,
    ImageGallery,
    ReverseDate
  ],
  providers: []
})
export class SharedModule {}
