import { Component, Input, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService, NbWindowService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { AuthService } from '../../../@core/services/auth.service';
import { Router } from '@angular/router';
import { NotificationComponent } from './notification/notification.component';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { UserGlobalService } from '../../../@core/services/user.global.service';
import * as _ from 'lodash';
import { ApiBridgeGlobalService } from '../../../@core/services/api-bridge.global.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {
    @Input() position = 'normal';
    user: any;
    cache: any;
    token: any;
    notifications:any = [];
    messageNotifications:any = [];
    unReadNotifications:any = [];
    public notifTab = NotificationComponent;

    userMenu = [
        {
            title: 'Profile',
            slug: 'profile'
        },
        {
            title: 'Log out',
            slug: 'logout'
        }
    ];

    profileImage = '';
    private socketInstance;
    private notifSocketInstance;
    downLinePercentage:any;
    keyword:string = '';
    constructor(
        public sidebarService: NbSidebarService,
        public auth: AuthService,
        public menuService: NbMenuService,
        public userService: UserService,
        public analyticsService: AnalyticsService,
        public layoutService: LayoutService,
        public router: Router,
        public sgs: SharedGlobalService,
        public ugs:UserGlobalService,

    ) {
        this.token = this.auth.getToken();
        this.loggedDefault().then(()=>{
            this.prepareSocket();
        });

        sgs.notificationEmitter.subscribe( async () => {
            await this.loggedDefault();
        });

        window.addEventListener('storage', (event) => {
            if (event.storageArea === localStorage) {
                this.loggedDefault();
                if(this.token !== this.auth.getToken()){
                    if(this.auth.getToken() !== null)
                    this.auth.login();
                }
            }
        }, false);
    }

  ngOnInit() {}

    prepareSocket() {
        this.socketInstance = this.sgs.ResponseSocket(this.user['role']+'s').subscribe( emitted => {
            if(emitted.success && emitted.data && emitted.data.uid == this.user.uid ){
                var emit = this.user.role == 'client' || this.user.role == 'clientsub'? emitted.data: emitted.data.data;
                if(emit.profileurl){
                    this.checkProfileImage(emit);
                }
                if(emit.fname || emit.lname){
                    this.user.fname = emit.fname || '';
                    this.user.lname = emit.lname || '';
                }
            }
        });

        this.notifSocketInstance = this.sgs.ResponseSocket('notifications').subscribe( emitted => {
            var index = _.findIndex(emitted.data.receivers, { 'id': this.user.uid});
            if(emitted.data.action == 'add' && index != -1){
            switch(this.user.role){
                case 'consultant':
                    if(emitted.data.createdby != this.user.uid){
                        this.PlayNotification();
                    }
                    this.getConsultantsNotifications();
                break;
                case 'client':
                    if(emitted.data.createdby != this.user.uid){
                        this.PlayNotification();
                    }
                    this.getClientNotifications();
                break;
                case 'clientsub': 
                if(emitted.data.createdby != this.user.uid){
                    this.PlayNotification();
                }
                this.getSubClientNotification();
                break;
                default:
                    console.log('User role in getting notification is undefined.')
                break;
            }
                // if(emitted.success && emitted.data.companyid == this.user['company'].companyid && index != -1){
                //   this.getNotifications();
                //   // console.log(emitted);
                    
                // }
            }else if(emitted.data.action == 'read' && emitted.data.id == this.user.uid){
                this.getNotifications();
            }else{
                console.log('Failed to determine the notification emit action.');
            }
        });
    }

    getNotifications(){
        switch(this.user.role){
            case 'consultant':
                this.getConsultantsNotifications();
            break;

            case 'client':
                this.getClientNotifications();
            break;

            case 'clientsub':
                this.getSubClientNotification();
            break;
            default:
                console.log('User role in getting notification is undefined.')
            break;
        }
    }

    getConsultantsNotifications(){
        this.notifications = [];
        this.messageNotifications = [];
        // console.log('getting consultant notifications');
        /*
        Role Descriptions
        - single role [used for the 1 is to 1  chat or specific role notification]
        - role with '&' [used for notification direct for 2 roles attached]
        - double role concatenated [used for the group chat specifically]
        */
        var  roles = [this.user.role, 'consultant&customer', 'clientConsultant', 'consultantCustomer'];
        this.sgs.request('get', 'notification/getConsultantNotifications', {companyid:this.user.company.companyid, role:roles }, async (response) => {
            if(response.success){
                var notif = _.orderBy(response.data, ['date_added'], ['desc']);
            //Chat Notifications
                var chats = _.orderBy(_.filter(notif, {'url':'/chat'}), ['date_added'], ['desc']);
                var chatConvo = _.groupBy(chats, 'chatId');  
                var unreadChatsNotif = _.filter(chats, {'isRead':false});
                var ids = _.keysIn(chatConvo);
                ids.map( i => {
                    var unread = _.filter(chatConvo[i], {'isRead':false});
                    chatConvo[i][0].unread = unread.length;
                    this.messageNotifications.push(chatConvo[i][0]);
                })

                //Push Notifications
                var pushNotifications =_.orderBy(_.filter(notif, {'url':'/notification'}), ['date_added'], ['desc']);           
                var unReadPushNotif = _.filter(pushNotifications, {'isRead':false});

                //Post Notifications
                var postNotifications =_.orderBy(_.filter(notif, {'url':'/market-me'}), ['date_added'], ['desc']);           
                var unReadPostNotif = _.filter(postNotifications, {'isRead':false});
                
                //Total Unread Notifications
                this.unReadNotifications = unReadPushNotif.length + unreadChatsNotif.length + unReadPostNotif.length;
                this.notifications = _.orderBy(pushNotifications.concat(postNotifications), ['date_added'], ['desc']);
            }
        }, {toaster:false})
    }

    getClientNotifications(){
        // console.log('getting client notifications');
        this.notifications = [];
        this.messageNotifications = [];
        /*
        Role Descriptions
        - single role [used for the 1 is to 1  chat or specific role notification]
        - role with '&' [used for notification direct for 2 roles attached]
        - double role concatenated [used for the group chat specifically]
        */
        var  roles = [this.user.role, 'clientConsultant', 'clientCustomer'];
        // console.log('Roles =====>', roles);
        this.sgs.request('get', 'notification/getConsultantNotifications', {companyid:this.user.uid, role:roles}, async (response) => {
            // console.log(response);
            var notif = _.orderBy(response.data, ['date_added'], ['desc']);
            var chats = _.orderBy(_.filter(notif, {'url':'/chat'}), ['date_added'], ['desc'])
            var notifications =_.orderBy(_.filter(notif, {'url':'/notification'}), ['date_added'], ['desc']);
            var chatConvo = _.groupBy(chats, 'chatId');                              
            var ids = _.keysIn(chatConvo);
            var unReadNotifications = _.filter(notifications, {'isRead':false});
            var unreadChats = _.filter(chats, {'isRead':false});
            this.unReadNotifications = unReadNotifications.length + unreadChats.length;
            this.notifications = notifications;
            // console.log(this.notifications);
            // console.log(ids);
            ids.map( i => {
                // console.log(chatConvo[i]);
                var unread = _.filter(chatConvo[i], {'isRead':false});
                chatConvo[i][0].unread = unread.length;
                this.messageNotifications.push(chatConvo[i][0]);
                // console.log(this.messageNotifications);
            })
        }, {toaster:false})
    }


    getSubClientNotification(){
        // console.log('getting client notifications');
        this.notifications = [];
        this.messageNotifications = [];
        /*
        Role Descriptions
        - single role [used for the 1 is to 1  chat or specific role notification]
        - role with '&' [used for notification direct for 2 roles attached]
        - double role concatenated [used for the group chat specifically]
        */
        // var  roles = [this.user.role, 'clientConsultant', 'clientCustomer'];
        // console.log('Roles =====>', roles);
        this.sgs.request('get', 'notification/getClientSubNotifications', {companyid:this.user.companyid, role:this.user.role}, async (response) => {
            var notif = _.orderBy(response.data, ['date_added'], ['desc']);
            var chats = _.orderBy(_.filter(notif, {'url':'/chat'}), ['date_added'], ['desc'])
            var notifications =_.orderBy(_.filter(notif, {'url':'/notification'}), ['date_added'], ['desc']);
            var chatConvo = _.groupBy(chats, 'chatId');                              
            var ids = _.keysIn(chatConvo);
            var unReadNotifications = _.filter(notifications, {'isRead':false});
            var unreadChats = _.filter(chats, {'isRead':false});
            this.unReadNotifications = unReadNotifications.length + unreadChats.length;
            this.notifications = notifications;
            // console.log(this.notifications);
            // console.log(ids);
            ids.map( i => {
                // console.log(chatConvo[i]);
                var unread = _.filter(chatConvo[i], {'isRead':false});
                chatConvo[i][0].unread = unread.length;
                this.messageNotifications.push(chatConvo[i][0]);
                // console.log(this.messageNotifications);
            })
        }, {toaster:false})
    }


    getAdminNotifications(){}

    PlayNotification(){
        let notif = new Audio('/assets/mp3/notif.wav');
            notif.loop = false;
            notif.play();
    }

    ngOnDestroy(){
        this.socketInstance.unsubscribe();
        this.notifSocketInstance.unsubscribe();
        // this.sgs.notificationEmitter.unsubscribe();
    }

    manageMenu(slug){
        switch(slug){
            case 'logout':
            break;
            case 'profile':
            this.router.navigate(['/'+this.user.role+'/profile']);
            break; 
        }
    }

    goToProfile(){
        this.router.navigate(['/'+this.user.role+'/profile']);
    }

    async loggedDefault(){
        try{
            return new Promise(async resolve => {
                this.user = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
                // console.log(this.user);
                this.getNotifications();
                this.checkProfileImage(this.user);
                this.cache = this.sgs.getCachedData();
                return resolve(true);
            })
        }catch{
            console.log('Failed to fetch user collection data!');
        }

    }

    checkProfileImage(user){
        //  console.log(user);
        if(user.role == 'client'){
            if(user.logourl){
                this.user.logourl = user.logourl;
            }
        }
        this.profileImage = user.profileurl? this.sgs.connection+'/images/'+user.profileurl: '../assets/images/logo_placeholder.png';
        // this.user.logourl = this.user.logourl? this.sgs.connection+'/images/'+this.user.profileurl: '../assets/images/logo_placeholder.png';
    }

    logout(){
        if(confirm('Are you sure you want to logout?')){
            this.auth.logout()
        }
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();
        return false;
    }

    toggleSettings(): boolean {
        this.sidebarService.toggle(false, 'settings-sidebar');
        return false;
    }

    goToHome() {
        this.menuService.navigateHome();
    }

    startSearch() {
        this.analyticsService.trackEvent('startSearch');
    }

    globalSearch() {
        this.router.navigate([`/${this.user['role']}/global-search/${this.keyword}`]);
        this.keyword = '';
    }

    clearGlobalSearch(){
        if(this.keyword == '' || this.keyword == undefined || !this.keyword.length){
            this.keyword = '';
        }
    }
}
