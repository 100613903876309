import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../@core/services/shared.global.service';


@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  statusCards = [
    {
      link:'',
      quantity:0,
      title:'Admin',
      type:'primary',
      iconClass:'fas fa-users-cog'
    },
    {
      link:'',
      quantity:1,
      title:'Clients',
      type:'success',
      iconClass:'fas fa-building'
    },
    {
      link:'',
      quantity:0,
      title:'Consultant',
      type:'info',
      iconClass:'fas fa-users'
    },
    {
      link:'',
      quantity:135,
      title:'Customers',
      type:'warning',
      iconClass:'fas fa-user-tag'
    },
  ]
  constructor(
    public sgs: SharedGlobalService
  ){}

  ngOnDestroy(){}

  ngOnInit() {
    this.getNoOfActiveUsers();
  }

  getNoOfActiveUsers(){
  /* Get the no. of users including the admin role */
    this.sgs.request('get', 'user/getAllActiveUsers', null, async (response) => {
        console.log(response);
        if(response.success && response.data && response.data.length){
          response.data.map((res, i) => {
            this.statusCards[i].quantity = res.count;
          })
        }else{
          this.statusCards[0].quantity = 0;
        }
    })
  }

  getNoOfActiveClient(){}
    
  

  getNoOfActiveCustomers(){

  }
  
}
