import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGlobalService } from '../../../../@core/services/user.global.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-consultant-add',
  templateUrl: './consultant-add.component.html',
  styleUrls: ['./consultant-add.component.scss']
})
export class ConsultantAddComponent implements OnInit {
  data;
  loading = true;
  private socketInstance;
  consultantForm:FormGroup;
  profileurl:any;
  userData:any;
  isSubmit:any;
  id:any;
  selectedType = {display:'Actual User', value:'consultant'};
  userTypes = [
    {display:'Actual User', value:'consultant'},
    {display:'Test User', value:'test-consultant'}
  ]
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    public ugs:UserGlobalService,
    public route: ActivatedRoute,
  ) {
    this.id = this.route.snapshot.params['id'];
   }

  ngOnInit() {
    this.getUser();
   
    this.buildForms();
  }
  
  async getUser(){
    this.userData = this.ugs.$userData? this.ugs.$userData:await this.ugs.getUserCollectionData();
  }
  
  buildForms(){
      this.consultantForm = this.fb.group({
        fname: ['', [Validators.required, Validators.minLength(2)]],
        lname: ['', [Validators.required, Validators.minLength(2)]],
        contact: [''],
        address: [''],
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]{2,}[.][a-zA-Z0-9-.]+$')]],
        profileurl: [{value:'', disabled: true}]
      })
  }

  ngOnDestroy(){
    // this.socketInstance.unsubscribe();
  }

  submit(){
    this.consultantForm.value.profileurl = this.profileurl;
    this.consultantForm.value.createdby = this.userData.uid;
    this.consultantForm.value.webpage = this.userData.webpage;
    if(this.consultantForm.valid && !this.isSubmit){
      this.isSubmit = true;
    }
    // if(!this.isEmailExist()){
    //     this.isSubmit = true;
    //     this.sgs.request('post', 'consultant/inviteConsultant', this.consultantForm.value, async (response) => {
    //         if(response.success){
    //             this.send(response.data);
    //             this.buildForms();
    //         }
    //     }, { cache:true, describe: "ERROR: Adding user" })
    // }else{
    //     this.isSubmit = false;
    // }

  }

  isEmailExist(){
    return this.sgs.request('get', 'consultant/checkIfEmailExist', {email:this.consultantForm.value.email}, async (response) => {
      if( response.success ){
        return true;
      }else{
        return false;
      }
    });
  }

  elEventListenerActive: boolean;
  openFile(ev, id){
    let file,
        el = document.getElementById(id);
        el.click();

        let handler = (fc) => {
          try{
        let fileList: any;
        let fd = new FormData();
            if(fc.target['files'][0]['name'] !== undefined){
              fileList = fc.target;
              let file: File = fileList.files[0];
              this.profileurl = file.name;
              ev.target.innerHTML = 'Change';
              
                fd.append('degree_attachment', file, file.name);

                this.sgs.request('post', 'xfile/profileImage', fd, response => {
                  if(response.success){
                    this.elEventListenerActive = false;
                    this.profileurl = response.data.name;
                    this.consultantForm.patchValue({profileurl:response.data.name});
                    
                    el.removeEventListener('change', handler);
                    // this.sgs.Modal({
                    //   header: `Image successfully uploaded`,
                    //   content: `
                    //     <b>Image name:</b> ${file.name} <br />
                    //     <b>Renamed by server to:</b> ${response.data.name} <br /><br />
                    //     <img width="100%" src="${this.sgs.connection}/images/${response.data.name}">
                    //   `,
                    //   buttonName: 'close'
                    // }, { size: 'md' });
                  }else{
                    this.profileurl = '';
                    el.removeEventListener('change', handler);
                  }
                });
            }else{
              this.profileurl = '';
              ev.target.innerHTML = 'Browse';
              this.elEventListenerActive = false;
              el.removeEventListener('change', handler);
            }
          }catch(e){
            this.profileurl = '';
            ev.target.innerHTML = 'Browse';
            this.elEventListenerActive = false;
            el.removeEventListener('change', handler);
          }
        }
        
        if( !this.elEventListenerActive ){
          el.addEventListener('change', handler);
          this.elEventListenerActive = true;
        }
  }

  selectUserType(t){
    this.selectedType = t;
  }

  send(user){
    const data = {
      appname:this.userData.appname,
      toEmail: user.email,
      toName: user.fname + " " + user.lname,
      subject: this.userData.companyname + ' App - New Consultant Signup',
      content: `
      
     
      
      
      <html>
      <style>
      /* This styles you should add to your html as inline-styles */
      /* You can easily do it with http://inlinestyler.torchboxapps.com/ */
      /* Copy this html-window code converter and click convert button */
      /* After that you can remove this style from your code */  
        
      body {
          margin: 0;
          padding: 0;
          mso-line-height-rule: exactly;
          min-width: 100%;
      }
      
      .wrapper {
          display: table;
          table-layout: fixed;
          width: 100%;
          min-width: 620px;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
      }
      
      body, .wrapper {
          background-color: #ffffff;
      }
      
      /* Basic */
      table {
          border-collapse: collapse;
          border-spacing: 0;
      }
      table.center {
          margin: 0 auto;
          width: 602px;
      }
      td {
          padding: 0;
          vertical-align: top;
      }
      
      .spacer,
      .border {
          font-size: 1px;
          line-height: 1px;
      }
      .spacer {
          width: 100%;
          line-height: 16px
      }
      .border {
          background-color: #e0e0e0;
          width: 1px;
      }
      
      .padded {
          padding: 0 24px;
          position:relative;
      }
      img {
          border: 0;
          -ms-interpolation-mode: bicubic;
      }
      .image {
          font-size: 12px;
      }
      .image img {
          display: block;
      }
      strong, .strong {
          font-weight: 700;
      }
      h1,
      h2,
      h3,
      p,
      ol,
      ul,
      li {
          margin-top: 0;
      }
      ol,
      ul,
      li {
          padding-left: 0;
      }
      
      a {
          text-decoration: none;
          color: #616161;
      }
      .btn {
          background-color:#2196F3;
          border:1px solid #2196F3;
          border-radius:2px;
          color:#ffffff;
          display:inline-block;
          font-family:Roboto, Helvetica, sans-serif;
          font-size:14px;
          font-weight:400;
          line-height:36px;
          text-align:center;
          text-decoration:none;
          text-transform:uppercase;
          width:200px;
          height: 36px;
          padding: 0 8px;
          margin: 0;
          outline: 0;
          outline-offset: 0;
          -webkit-text-size-adjust:none;
          mso-hide:all;
      }
      
      /* Top panel */
      .title {
          text-align: left;
      }
      
      .subject {
          text-align: right;
      }
      
      .title, .subject {
          width: 300px;
          padding: 8px 0;
          color: #616161;
          font-family: Roboto, Helvetica, sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
      }
      
      /* Header */
      .logo {
          padding: 16px 0;
      }
      
      /* Logo */
      .logo-image {
      
      }
      
      /* Main */
      .main {
          -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
          -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
      }
      
      /* Content */
      .columns {
          margin: 0 auto;
          width: 600px;
          background-color: #ffffff;
          font-size: 14px;
      }
      
      .column {
          text-align: left;
          background-color: #ffffff;
          font-size: 14px;
      }
      
      .column-top {
          font-size: 24px;
          line-height: 24px;
      }
      
      .content {
          width: 100%;
      }
      
      .column-bottom {
          font-size: 8px;
          line-height: 8px;
      }
      
      .content h1 {
          margin-top: 0;
          margin-bottom: 16px;
          color: #212121;
          font-family: Roboto, Helvetica, sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
      }
      company-logo{
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .content p {
          margin-top: 0;
          margin-bottom: 16px;
          color: #212121;
          font-family: Roboto, Helvetica, sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
      }
      .content .caption {
          color: #616161;
          font-size: 12px;
          line-height: 20px;
      }
      
      /* Footer */
      .signature, .subscription {
          vertical-align: bottom;
          width: 300px;
          padding-top: 8px;
          margin-bottom: 16px;
      }
      
      .signature {
          text-align: left;
      }
      .subscription {
          text-align: right;
      }
      
      .signature p, .subscription p {
          margin-top: 0;
          margin-bottom: 8px;
          color: #616161;
          font-family: Roboto, Helvetica, sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
      }
      </style>
      
      <center class="wrapper">
          <table class="top-panel center" width="602" border="0" cellspacing="0" cellpadding="0">
              <tbody>
              <tr>
                  <td class="title" width="300">${this.userData.companyname}</td>
                  <td class="subject" width="300"><a class="strong" href="${this.userData.webpage}" target="_blank">${this.userData.webpage}</a></td>
              </tr>
              <tr>
                  <td class="border" colspan="2">&nbsp;</td>
              </tr>
              </tbody>
          </table>
      
          <div class="spacer">&nbsp;</div>
      
          <table class="main center" width="602" border="0" cellspacing="0" cellpadding="0">
              <tbody>
              <tr>
                  <td class="column">
                      <div class="column-top">&nbsp;</div>
                      <table class="content" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                          <tr>
                              <td class="padded">
                                <h1>Welcome ${user.fname} to ${this.userData.companyname} App!</h1>
                                <!-- <p>We are glad to have you onboard.</p> -->
                                <br>
                                <p>Please follow the 4-easy steps to confirm your account.</p>
                                <p><b>Step 1:</b> Click on the link provided: <a href="${this.userData.webportal}/#/verification/consultant/${ user.id }/${this.userData.uid}">Confirm My Account</a></p>
                                <p><b>Step 2:</b> Create and Memorize your password to activate your account</p> 
                                <p><b>Step 3:</b> Click on the download link and install the app</p> 
                                <p><b>Step 4:</b> Login to your ${this.userData.appname} using your email address that was displayed in "Step 2" and the password you created, and expand your network!</p>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <div class="column-bottom">&nbsp;</div>
                  </td>
              </tr>
              </tbody>
          </table>
      
          <div class="spacer">&nbsp;</div>
      
          <table class="footer center" width="602" border="0" cellspacing="0" cellpadding="0">
              <tbody>
              <tr>
                  <td class="border" colspan="2">&nbsp;</td>
              </tr>
              <tr>
                  <td class="signature" width="300">
                     ${this.userData['signature']}
                  </td>
                  <td class="subscription" width="300">
                      <div class="logo-image">
                          <a  href="https://zavoloklom.github.io/material-design-iconic-font/" target="_blank" ><img src="${this.userData.webportal}/images/${this.userData.logourl}" width="60" height="auto"></a>
                      </div>
                      <p>
                          <a class="strong block" href="${this.userData.webportal}" target="_blank">
                          App Login Page
                          </a>
                        
                  </td>
              </tr>
              </tbody>
          </table>
      </center>
                `
    }
    this.sgs.request('post','communication/sendEmail', data, async response => {
      if(response.success){
        this.sgs.Toaster('success', 'Invitation Sent', 'The invitation was sent successfully.');
      }else{
        this.sgs.Toaster('warning', 'Email not Sent', 'The invitation was not sent successfully. Please try to resend in the list.');
      }
    })
  }




  // <img  style=" width:20%; border:4px solid #d76d9d;" src="${this.userData.webportal}/images/${this.userData.logourl}">
  //                 <br>Welcome ${user.fname}  to the  ${this.userData.companyname} App! 
  //                 <br> 
  //                 <p>We are glad to have you onboard.</p><p>Please follow the 4-easy steps to confirm your account. </p> 
  //                 <p>Step 1: Click on the link provided: <a href="${this.userData.webportal}/#/verification/consultant/${ user.id }/${this.userData.uid}">Confirm My Account</a></p>
  //                 <p>Step 2: Create and Memorize your password to activate your account</p> 
  //                 <p>Step 3: Click on the download link and install the app</p> 
  //                 <p>Step 4. Login to your app using your email address that was displayed in "Step 2" and the password you created, and expand your network!</p>
  //                 <br>
  //                 <br>
  //                 ${this.userData['signature']}
  //               </html>


  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }



  

}
