import { Component, OnInit } from '@angular/core';
import { SharedGlobalService } from '../../../@core/services/shared.global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'ngx-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.scss']
})
export class ClientAddComponent implements OnInit {
  data;
  loading = true;
  private socketInstance;
  clientForm:FormGroup;
  logourl:any = '';
  constructor(
    public sgs: SharedGlobalService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
  ) {
   }

  ngOnInit() {
    this.buildForms();
  }

  buildForms(){
      this.clientForm = this.fb.group({
        companyname: ['', [Validators.required, Validators.minLength(2)]],
        fname: ['', [Validators.required, Validators.minLength(2)]],
        lname: ['', [Validators.required, Validators.minLength(2)]],
        contact: [''],
        address: [''],
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        logourl: [{value:'', disabled: true}]
      })
    
  }

  ngOnDestroy(){
    // this.socketInstance.unsubscribe();
  }

  submit(){
    var packageName = 'com.myempowerapp.'+this.clientForm.value.companyname.toLocaleLowerCase().replace(/[^A-Z0-9]+/ig, "");
    this.clientForm.value.logourl = this.logourl;
    this.clientForm.value.packagename = packageName;
    this.sgs.request('post', 'client/createClient', this.clientForm.value, async (response) => {
      if(response.success){
        this.sgs.Toaster('success', 'Success', 'New client has been added.');
        this.buildForms();
      }
    }, { cache:true, describe: "ERROR: Adding user" })
  }

  elEventListenerActive: boolean;
  openFile(ev, id){
    let file,
        el = document.getElementById(id);
        el.click();


        let handler = (fc) => {
          try{
        let fileList: any;
        let fd = new FormData();
            if(fc.target['files'][0]['name'] !== undefined){
              fileList = fc.target;
              let file: File = fileList.files[0];
              this.logourl = file.name;
              ev.target.innerHTML = 'Change';
              
                fd.append('degree_attachment', file, file.name);

                this.sgs.request('post', 'xfile/logoImage', fd, response => {
                  if(response.success){
                    this.elEventListenerActive = false;
                    this.logourl = response.data.name;
                    this.clientForm.patchValue({logourl:response.data.name});
                    
                    el.removeEventListener('change', handler);
                    this.sgs.Modal({
                      header: `Image successfully uploaded`,
                      content: `
                        <b>Image name:</b> ${file.name} <br />
                        <b>Renamed by server to:</b> ${response.data.name} <br /><br />
                        <img width="100%" src="${this.sgs.connection}/images/${response.data.name}">
                      `,
                      buttonName: 'close'
                    }, { size: 'md' });
                  }else{
                    this.logourl = '';
                    el.removeEventListener('change', handler);
                  }
                });


            }else{
              this.logourl = '';
              ev.target.innerHTML = 'Browse';
              this.elEventListenerActive = false;
              el.removeEventListener('change', handler);
            }
          }catch(e){
            this.logourl = '';
            ev.target.innerHTML = 'Browse';
            this.elEventListenerActive = false;
            el.removeEventListener('change', handler);
          }
        }
        
        if( !this.elEventListenerActive ){
          el.addEventListener('change', handler);
          this.elEventListenerActive = true;
        }
  }







  // addUser(){
  //   const activeModal = this.ngbModal.open(AddUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  // }

  // updateUser(id){
  //   const activeModal = this.ngbModal.open(UpdateUserComponent, { size: 'sm', container: 'nb-layout', windowClass: 'min_height' });
  //   activeModal.componentInstance.uid = id;
  // }



  

}
